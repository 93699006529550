<template>
    <client-page>
        <solution-sub-visual />
        <solution-tab />
        <sub-section tit="Safe IP" titTxt="IP관리 솔루션" className="section--first">
            <tit-logo tit="제품 개요" logo="/images/sub/solution/safeip/top-logo.jpg">
                Safe IP는 내부 네트워크 사용 단말의 가시성을 확보하고, 네트워크 자원(IP/MAC) 관리를 중앙화 및 자동화하여 편리하게 사용할 수 <br class="d-none d-lg-block">
                있는 IP 관리 솔루션입니다.
            </tit-logo>
        </sub-section>
        <sub-section sectionTit="제품 구성도" className="grey lighten-5">
            <v-img src="/images/sub/solution/safeip/safeip-img1.jpg" class="d-none d-md-block w-100 border"  data-aos="fade-up" data-aos-delay="150"/>
            <v-img src="/images/sub/solution/safeip/safeip-img1-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border"  data-aos="fade-up" data-aos-delay="150"/>
        </sub-section>
        <sub-section sectionTit="특장점">
            <v-row no-gutters class="icon-cards cols-4">
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="01" icon="/images/sub/solution/safeip/safeip-icon1-1.svg">
                        <template slot="tit">
                            효율적인 네트워크 <br class="d-none d-lg-block">
                            자원 관리
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 네트워크 사용 단말 가시성 확보
                            </li>
                            <li class="dot-text">
                                · IP/MAC 관리 중앙화/자동화
                            </li>
                            <li class="dot-text">
                                · 편리한 IP 관리 정책 <br class="d-none d-lg-block">
                                등록/수정/삭제
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="02" icon="/images/sub/solution/safeip/safeip-icon1-2.svg">
                        <template slot="tit">
                            사용자 컬럼 <br class="d-none d-lg-block">
                            설정
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 관리자 필요에 따라 사용자 정의 필드 설정
                            </li>
                            <li class="dot-text">
                                · 다중 IP선택 후 정보 일괄 입력
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="03" icon="/images/sub/solution/safeip/safeip-icon1-3.svg">
                        <template slot="tit">
                            네트워크 장비 <br class="d-none d-lg-block">
                            관리업무 전산화
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 고객 운영환경에 맞는 OUI
                            </li>
                            <li class="dot-text">
                                · 관리자 부주의로 인한 장애 발생 위험 최소화
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="04" icon="/images/sub/solution/safeip/safeip-icon1-4.svg">
                        <template slot="tit">
                            관리자 운영 <br class="d-none d-lg-block">
                            편의성
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 관리자 맞춤 동적 대시보드
                            </li>
                            <li class="dot-text">
                                · IP/MAC 사용이력 감사 보고서
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section sectionTit="주요기능" className="grey lighten-5">
            <v-row no-gutters class="icon-cards cols-4">
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="01" icon="/images/sub/solution/safeip/safeip-icon2-1.svg">
                        <template slot="tit">
                            네트워크 자동 <br class="d-none d-lg-block">
                            정보 자동 수집
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="02" icon="/images/sub/solution/safeip/safeip-icon2-2.svg">
                        <template slot="tit">
                            IP/MAC 고정 <br class="d-none d-lg-block">
                            및 장비 보호
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="03" icon="/images/sub/solution/safeip/safeip-icon2-3.svg">
                        <template slot="tit">
                            IP/MAC 사용 <br class="d-none d-lg-block">
                            현황 관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="04" icon="/images/sub/solution/safeip/safeip-icon2-4.svg">
                        <template slot="tit">
                            비인가 단말 <br class="d-none d-lg-block">
                            사용 차단
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="05" icon="/images/sub/solution/safeip/safeip-icon2-5.svg">
                        <template slot="tit">
                            비인가 네트워크 <br class="d-none d-lg-block">
                            탐지 차단
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="06" icon="/images/sub/solution/safeip/safeip-icon2-6.svg">
                        <template slot="tit">
                            비인가 DHCP <br class="d-none d-lg-block">
                            서버 탐지
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="07" icon="/images/sub/solution/safeip/safeip-icon2-7.svg">
                        <template slot="tit">
                            동적 IP 관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="08" icon="/images/sub/solution/safeip/safeip-icon2-8.svg">
                        <template slot="tit">
                            정책관리 및 <br class="d-none d-lg-block">
                            보고서
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section className="section--last">
            <sub-section small sectionTit="도입분야" className="page-section--first">
                <v-row>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="150">
                        <icon-frame tit="금융" icon="/images/sub/solution/safeip/safeip-icon3-1.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="200">
                        <icon-frame tit="공공" icon="/images/sub/solution/safeip/safeip-icon3-2.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="250">
                        <icon-frame tit="정보통신" icon="/images/sub/solution/safeip/safeip-icon3-3.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="300">
                        <icon-frame tit="기업" icon="/images/sub/solution/safeip/safeip-icon3-4.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="350">
                        <icon-frame tit="병원" icon="/images/sub/solution/safeip/safeip-icon3-5.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="400">
                        <icon-frame tit="교육" icon="/images/sub/solution/safeip/safeip-icon3-6.svg" />
                    </v-col>
                </v-row>
            </sub-section>
            <sub-section small sectionTitSm sectionTit="제품 인증">
                <p class="page-text dot-text grey--text" data-aos="fade-up" data-aos-delay="150">
                    · GS 인증
                </p>
            </sub-section>
            <sub-section small sectionTitSm sectionTit="특허">
                <p class="page-text dot-text grey--text" data-aos="fade-up" data-aos-delay="150">
                    · 자동 개인 아이피 주소 할당 대역에 대한 제어 시스템 및 방법
                </p>
            </sub-section>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SolutionSubVisual from "@/components/client/solution/solution-sub-visual.vue";
import SolutionTab from "@/components/client/solution/solution-tab.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";
import IconFrame from "@/components/client/sub/icon-frame-primary.vue";

export default {
    components: {
        ClientPage,
        SolutionSubVisual,
        SolutionTab,
        SubSection,
        TitLogo,
        IconCard,
        IconFrame,
    },
    data() {
        return {
        };
    },
};
</script>
