var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('solution-sub-visual'), _c('solution-tab'), _c('sub-section', {
    attrs: {
      "tit": "i-oneJTac",
      "titTxt": "원격접속 재택근무 보안솔루션",
      "className": "section--first"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "className": "page-section--first"
    }
  }, [_c('tit-logo', {
    attrs: {
      "tit": "제품 개요",
      "logo": "/images/sub/solution/ionejtac/top-logo.jpg"
    }
  }, [_vm._v(" 아이원재택(i-oneJTac)은 재택근무 보안 고려사항을 준수하며, 안전한 재택근무를 위한 편리한 원격접속과 강화된 보안까지 한번에 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 제공하는 재택근무 솔루션입니다. ")])], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "도입 필요성"
    }
  }, [_c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('image-card', {
    attrs: {
      "image": "/images/sub/solution/ionejtac/ionejtac-img1-1.jpg",
      "tit": "보안 컴플라이언스 준수"
    }
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 금융감독원 전자금융감독규정시행세칙 개정 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 금융보안원 재택근무 보안 안내서 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 방산 및 협력업체 비대면·재택근무 보안 안내서 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('image-card', {
    attrs: {
      "image": "/images/sub/solution/ionejtac/ionejtac-img1-2.jpg",
      "tit": "원격접속 관리"
    }
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 서버와 단말기 클라이언트 간 상호인증 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 사용자별, 부서별 보안정책 설정 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 원격접속 현황 모니터링 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('image-card', {
    attrs: {
      "image": "/images/sub/solution/ionejtac/ionejtac-img1-3.jpg",
      "tit": "보안환경 마련"
    }
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 재택PC에 내부업무 환경 수준의 보안 적용 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 재택PC에 백신 설치 및 최신 업데이트 관리 ")])])], 1)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "className": "grey lighten-5"
    }
  }, [_c('sub-section', {
    attrs: {
      "sectionTit": "제품 구성도",
      "small": "",
      "className": "page-section--first"
    }
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/ionejtac/ionejtac-img2.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/ionejtac/ionejtac-img2-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "프로세스"
    }
  }, [_c('v-row', {
    staticClass: "row--large row-cols-md-5",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('icon-frame-step', {
    attrs: {
      "step": "01",
      "width": "20",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon1-1.svg"
    }
  }, [_vm._v(" 재택근무 신청 및 승인 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" (기간 및 용도) ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('icon-frame-step', {
    attrs: {
      "step": "02",
      "width": "40",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon1-2.svg"
    }
  }, [_vm._v(" 클라이언트 로그인 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" (2-Factor 인증) ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('icon-frame-step', {
    attrs: {
      "step": "03",
      "width": "60",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon1-3.svg"
    }
  }, [_vm._v(" 보안서약서 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 동의 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('icon-frame-step', {
    attrs: {
      "step": "04",
      "width": "80",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon1-4.svg"
    }
  }, [_vm._v(" 재택근무 수행 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" (사내 업무망 PC 원격접속) ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('icon-frame-step', {
    attrs: {
      "step": "05",
      "width": "100",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon1-5.svg"
    }
  }, [_vm._v(" 재택근무 종료 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 로그아웃 ")])], 1)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "특장점"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon2-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 편리한 원격접속 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 및 안전한 보안 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 하나의 솔루션으로 원격접속과 안전한 보안 환경 제공 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 재택단말기 보안 환경 점검 및 설정가이드 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon2-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 보안규정에 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 최적화된 솔루션 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 재택근무 시 필수 의무사항 적용 및 권고사항 핵심 요소 반영 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 주요 보안 위협 요소 통제 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon2-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 강력한 보안 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 환경 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 불필요한 외부 접속 차단 (MAC 주소 자동 등록) ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 2-Factor 인증 지원 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 외부 단말기 보안 관리 및 내부정보 유출 방지 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "04",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon2-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 사용 편의성 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 및 효율성 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 간편한 원격접속, 빠른 반응속도 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 멀티 모니터 및 사운드 지원 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 재택근무자 모니터링 및 관리 ")])])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "주요기능",
      "className": "grey lighten-5"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "01",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon3-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 외부 단말기 보안관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "02",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon3-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 내부정보 유출방지 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "03",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon3-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 내부망 접근통제 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "04",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon3-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 사용자 보안인증 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "05",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon3-5.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 통신구간 보안관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "06",
      "icon": "/images/sub/solution/ionejtac/ionejtac-icon3-6.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 보안정책 설정 ")])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTitSm": "",
      "sectionTit": "제품 인증",
      "className": "section--last"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" · GS 인증, 클라우드 서비스 확인제 인증 ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }