<template>
    <client-page>
        <sub-visual titOutlined="Company" tit="Introduction" sh="회사소개" tabActive="오시는 길" bg="/images/sub/visual/sv--about.jpg" />
        <sub-section className="section--location section--first section--last">
            <div class="mb-40 mb-md-60 mb-lg-80">
                <v-img src="/images/sub/about/location/logo.jpg" contain max-height="160" class="mb-20 mb-lg-40"/>
                <v-layout justify-center align-center>
                    <div class="icon-wrap mr-6 mr-lg-12">
                        <i class="icon icon-point" />
                    </div>
                    <h4 class="tit--sm">
                        {{ $t("address") }}
                    </h4>
                </v-layout>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.8510271216633!2d126.8570440042164!3d37.55857347697548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9c05fb44fa43%3A0xb8e100e3dd19bda4!2z6rCA7JaRIOuNlOumrOu4jOyVhOuEiOu5hOymiO2DgOybjA!5e0!3m2!1sko!2skr!4v1719795876255!5m2!1sko!2skr" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
            <v-card elevation="5" class="loctaion-info">
                <div class="py-20 py-md-30 py-lg-40">
                    <v-row no-gutters>
                        <v-col cols="12" md="4" class="border-md-end mb-10 mb-md-0">
                            <v-layout align-center class="justify-md-center px-20 px-md-0" data-aos="fade-up" data-aos-delay="100">
                                <div class="icon-wrap mr-6 mr-lg-12">
                                    <i class="icon icon-tel" />
                                </div>
                                <a href="tel:02-780-2939" class="tit-txt font-weight-bold">
                                    02-780-2939
                                </a>
                            </v-layout>
                        </v-col>
                        <v-col cols="12" md="4" class="border-md-end mb-10 mb-md-0" data-aos="fade-up" data-aos-delay="150">
                            <v-layout align-center class="justify-md-center px-20 px-md-0">
                                <div class="icon-wrap mr-6 mr-lg-12">
                                    <i class="icon icon-fax" />
                                </div>
                                <h5 class="tit-txt font-weight-bold">
                                    02-785-2939
                                </h5>
                            </v-layout>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-layout align-center class="justify-md-center px-20 px-md-0" data-aos="fade-up" data-aos-delay="200">
                                <div class="icon-wrap mr-6 mr-lg-12">
                                    <i class="icon icon-mail" />
                                </div>
                                <a href="mailto:sales@linknkor.co.kr" class="tit-txt font-weight-bold">
                                    sales@linknkor.co.kr
                                </a>
                            </v-layout>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </sub-section>  
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {
        };
    },
};
</script>

<style lang="scss" scoped>
.section--location{
    background-image: url(/images/sub/about/location/bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.icon-wrap{
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid var(--v-grey-lighten3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
iframe{
    width: 100vw;
    height: 550px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
}
.loctaion-info{
    margin-top: -102px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .loctaion-info{
        margin-top: -54px;
    }
}
@media (min-width:1024px){
    .icon-wrap{
        min-width: 60px;
        width: 60px;
        height: 60px;
    }
    .loctaion-info{
        margin-top: -70px;
    }
}
@media (min-width:1200px){
}
</style>
