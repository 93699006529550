<template>
    <div v-if="className == 'sub-tab-wrap'" :class="className">
        <v-container>
            <template v-for="item in gnbs">
                <ul class="tab tab--underline" :key="item.title" v-if="item.title == sh">
                    <li v-for="child in item.children" :key="child.title" class="tab__li" :class="{ active: tabActive == child.title }">
                        <router-link v-if="!child.href" :to="child.path" class="tab__btn"><span>{{ $t(child.title) }}</span></router-link>
                        <a v-else :href="child.href" target="_blank" class="tab__btn"><span>{{ $t(child.title) }}</span></a>
                    </li>
                </ul>
            </template>
        </v-container>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className + '__li'" :key="item.title">
                <router-link :to="item.path" :class="className + '__link'">
                    <h2>{{ $t(item.title) }}</h2>
                </router-link>
            </li>
            <li v-else :class="className + '__li'" :key="item.title">
                <div v-if="isMobile" :class="className + '__link gnb__link--toggle'">
                    <h2>{{ $t(item.title) }}</h2>
                </div>
                <router-link v-else :to="item.path" :class="className + '__link gnb__link--toggle'">
                    <h2>{{ $t(item.title) }}</h2>
                    </router-link>
                <ul :class="className + '__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link v-if="!child.href" :to="child.path">{{ $t(child.title) }}</router-link>
                        <a v-else :href="child.href" target="_blank">{{ $t(child.title) }}</a>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className: String,
        // indexNum: String,
        sh: String,
        tabActive: String,
    },
    data: function() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs: [
                {
                    title: "Introduction",
                    path: "/en/about/clients",
                    children: [
                        {
                            title: "Client",
                            path: "/en/about/clients",
                        },
                        {
                            title: "History",
                            path: "/en/about/history",
                        },
                        {
                            title: "Location",
                            path: "/en/about/location",
                        },
                    ],
                },
                {
                    title: "Service",
                    path: "/en/service",
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted: function() {},
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>
