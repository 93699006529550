var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(1), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": "회사명을 입력하세요."
    },
    model: {
      value: _vm.form.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(2), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": "이름을 입력하세요."
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(3), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "w-md-240px",
    attrs: {
      "items": _vm.prependPhones,
      "placeholder": "연락처",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone1,
      callback: function ($$v) {
        _vm.phone1 = $$v;
      },
      expression: "phone1"
    }
  }, 'v-select', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "‘-’를 제외하고 입력하세요."
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone2,
      callback: function ($$v) {
        _vm.phone2 = $$v;
      },
      expression: "phone2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(4), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이메일을 입력하세요."
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email1,
      callback: function ($$v) {
        _vm.email1 = $$v;
      },
      expression: "email1"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "prefix": "@",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email2,
      callback: function ($$v) {
        _vm.email2 = $$v;
      },
      expression: "email2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "persistent-hint": "",
      "items": _vm.prependEmails,
      "item-text": "text",
      "item-value": "value",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.email2 = _vm.email3;
      }
    },
    model: {
      value: _vm.email3,
      callback: function ($$v) {
        _vm.email3 = $$v;
      },
      expression: "email3"
    }
  }, 'v-select', _vm.$attrs, false))], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(5), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "i-oneNet",
      "label": `i-oneNet`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "i-oneNac",
      "label": `i-oneNac`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "Safe IP",
      "label": `Safe IP`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "NGS",
      "label": `NGS`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "i-oneJTac",
      "label": `i-oneJTac`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "mSABER",
      "label": `mSABER`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "인프라-서버 및 주변기기",
      "label": `인프라-서버 및 주변기기`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "인프라-네트워크",
      "label": `인프라-네트워크`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "인프라-상용소프트웨어",
      "label": `인프라-상용소프트웨어`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "OT보안-nozomi",
      "label": `OT보안-nozomi`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--primary",
    attrs: {
      "hide-details": "",
      "value": "클라우드-오케스트로",
      "label": `클라우드-오케스트로`
    },
    model: {
      value: _vm.form.solution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(6), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-textarea', {
    attrs: {
      "rows": "8",
      "placeholder": "문의내용을 입력하세요",
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)])]), _c('div', {
    staticClass: "form-agree-box"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 수집·이용 동의",
      "value": "개인정보 수집·이용 동의",
      "hide-details": ""
    },
    model: {
      value: _vm.agree,
      callback: function ($$v) {
        _vm.agree = $$v;
      },
      expression: "agree"
    }
  }), _c('v-btn', {
    staticClass: "ml-12 ml-lg-24 v-btn--underline",
    attrs: {
      "onclick": "window.open('/privacy', '', 'width=483,height=700');",
      "color": "grey lighten-1",
      "text": "",
      "height": "auto"
    }
  }, [_vm._v("전문보기")])], 1), _c('v-layout', {
    staticClass: "mt-40 mt-lg-60",
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-btn', {
    staticClass: "min-w-180px min-w-lg-240px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("문의하기")])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-right font-size-12 font-size-lg-14 line-height-1 grey--text mb-12 mb-lg-20"
  }, [_c('span', {
    staticStyle: {
      "color": "#FF0000"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("회사명"), _c('span', {
    staticStyle: {
      "color": "#FF0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("이름"), _c('span', {
    staticStyle: {
      "color": "#FF0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("연락처 "), _c('span', {
    staticStyle: {
      "color": "#FF0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("이메일 "), _c('span', {
    staticStyle: {
      "color": "#FF0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("솔루션 "), _c('span', {
    staticStyle: {
      "color": "#FF0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("문의내용 "), _c('span', {
    staticStyle: {
      "color": "#FF0000"
    }
  }, [_vm._v("*")])]);

}]

export { render, staticRenderFns }