var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Contact",
      "tit": "Us",
      "sh": "상담문의",
      "bg": "/images/sub/visual/sv--contact.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--first section--last"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v(" 충분한 상담 후 견적까지 안내 받아보세요. ")]), _c('p', {
    staticClass: "tit-txt mt-12 mt-lg-20"
  }, [_vm._v(" 링크넷코리아의 각 솔루션 담당자가 문의 내용에 대하여 빠르게 회신하여 드립니다. "), _c('br'), _vm._v(" 문의 내용을 상세히 기재할수록 정확한 답변이 가능합니다. ")])]), _c('form-mail')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }