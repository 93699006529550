<template>
    <client-page>
        <solution-sub-visual />
        <solution-tab />
        <sub-section tit="NGS" titTxt="시스템 접근제어 및 계정관리 솔루션" className="section--first">
            <tit-logo tit="제품 개요" logo="/images/sub/solution/ngs/top-logo.jpg">
                NGS는 내부시스템에 접속하는 경로를 일원화하여 시스템 접근제어와 안전한 계정관리 및 패스워드 관리를 한번에 제공하는 3in1 <br class="d-none d-lg-blockk">
                솔루션입니다.
            </tit-logo>
        </sub-section>
        <sub-section sectionTit="제품 구성도" className="grey lighten-5">
            <v-img src="/images/sub/solution/ngs/ngs-img1.jpg" class="d-none d-md-block w-100 border"  data-aos="fade-up" data-aos-delay="150"/>
            <v-img src="/images/sub/solution/ngs/ngs-img1-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border"  data-aos="fade-up" data-aos-delay="150"/>
        </sub-section>
        <sub-section sectionTit="특장점">
            <v-row no-gutters class="icon-cards cols-3">
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="01" icon="/images/sub/solution/ngs/ngs-icon1-1.svg">
                        <template slot="tit">
                            3in1 통합 관리
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 시스템 접근제어, 계정관리, 패스워드 관리 <br class="d-none d-lg-block">
                                통합제공
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="02" icon="/images/sub/solution/ngs/ngs-icon1-2.svg">
                        <template slot="tit">
                            보안성 강화
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 시스템 접속 경로 일원화
                            </li>
                            <li class="dot-text">
                                · 시스템 우회접속 탐지 및 차단
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" md="4">
                    <icon-card list num="03" icon="/images/sub/solution/ngs/ngs-icon1-3.svg">
                        <template slot="tit">
                            운영 편의성
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 시스템 계정/패스워드 관리 자동화
                            </li>
                            <li class="dot-text">
                                · 실시간 모니터링 및 이력 보고서 제공
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section sectionTit="주요기능" className="grey lighten-5">
            <v-row no-gutters class="icon-cards cols-4">
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="01" icon="/images/sub/solution/ngs/ngs-icon2-1.svg">
                        <template slot="tit">
                            접근 권한 통제
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="02" icon="/images/sub/solution/ngs/ngs-icon2-2.svg">
                        <template slot="tit">
                            접속 세션 통제
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="03" icon="/images/sub/solution/ngs/ngs-icon2-3.svg">
                        <template slot="tit">
                            로그 감사
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="04" icon="/images/sub/solution/ngs/ngs-icon2-4.svg">
                        <template slot="tit">
                            사용자 인증
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="05" icon="/images/sub/solution/ngs/ngs-icon2-5.svg">
                        <template slot="tit">
                            보안 취약점 <br class="d-none d-lg-block">
                            점검
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="06" icon="/images/sub/solution/ngs/ngs-icon2-6.svg">
                        <template slot="tit">
                            계정 및 패스워드 관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="07" icon="/images/sub/solution/ngs/ngs-icon2-7.svg">
                        <template slot="tit">
                            DBMS 접근 <br class="d-none d-lg-block">
                            통제
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="08" icon="/images/sub/solution/ngs/ngs-icon2-8.svg">
                        <template slot="tit">
                            모니터링 및 <br class="d-none d-lg-block">
                            보고서
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section className="section--last">
            <sub-section small sectionTit="도입분야" className="page-section--first">
                <v-row>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="150">
                        <icon-frame tit="국방" icon="/images/sub/solution/ngs/ngs-icon3-1.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="200">
                        <icon-frame tit="공공" icon="/images/sub/solution/ngs/ngs-icon3-2.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="250">
                        <icon-frame tit="클라우드 접근제어·계정관리" icon="/images/sub/solution/ngs/ngs-icon3-3.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="300">
                        <icon-frame tit="기업" icon="/images/sub/solution/ngs/ngs-icon3-4.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="350">
                        <icon-frame tit="금융·병원" icon="/images/sub/solution/ngs/ngs-icon3-5.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="400">
                        <icon-frame tit="교육" icon="/images/sub/solution/ngs/ngs-icon3-6.svg" />
                    </v-col>
                </v-row>
            </sub-section>
            <sub-section small sectionTitSm sectionTit="제품 인증">
                <p class="page-text dot-text grey--text" data-aos="fade-up" data-aos-delay="150">
                    · CC인증(EAL3), GS인증
                </p>
            </sub-section>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SolutionSubVisual from "@/components/client/solution/solution-sub-visual.vue";
import SolutionTab from "@/components/client/solution/solution-tab.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";
import IconFrame from "@/components/client/sub/icon-frame-primary.vue";

export default {
    components: {
        ClientPage,
        SolutionSubVisual,
        SolutionTab,
        SubSection,
        TitLogo,
        IconCard,
        IconFrame,
    },
    data() {
        return {
        };
    },
};
</script>
