<template>
    <div>
        <icon-frame :icon="icon" class="mb-4 mb-lg-8" />
        <p class="page-text text-center word-keep-all">
            {{tit}}
        </p>
    </div>
</template>

<script>
import IconFrame from "@/components/client/sub/icon-frame.vue";

export default {
    components: {
        IconFrame,
    },
    props: {
        icon: { type: String, default: "" },
        tit: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
</style>
