<template>
    <div class="v-tabs-wrap mt-40 mt-md-60 mt-lg-80">
        <v-container>
            <v-tabs hide-slider height="auto" class="v-tabs--primary">
                <v-tab to="/solution/security/i-oneNet">i-oneNet</v-tab>
                <v-tab to="/solution/security/i-oneNAC">i-oneNAC</v-tab>
                <v-tab to="/solution/security/SafeIP">Safe IP</v-tab>
                <v-tab to="/solution/security/NGS">NGS</v-tab>
                <v-tab to="/solution/security/i-oneJTac">i-oneJTac</v-tab>
                <v-tab to="/solution/security/mSABER">mSABER</v-tab>
            </v-tabs>
        </v-container>
    </div>
</template>

<script>

export default {
    props: {
    },
};
</script>

<style lang="scss" scoped>
.v-tabs--primary{
    .v-tab{
        transition: 0.15s ease-out;
        white-space: nowrap;
        flex: 1 1 0;
        height: 40px;
        font-size: 1.4rem;
        color: #111 !important;
        background-color: var(--v-grey-lighten5);
        font-family: "Play","Pretendard","Nanum Gothic","Apple SD Gothic Neo",Helvetica,Arial,sans-serif;
        &.v-tab--active{
            background-color: var(--v-primary-base);
            color: #fff !important;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-tabs--primary{
        .v-tab{
            height: 48px;
            font-size: 1.6rem;
        }
        .v-tab:hover{
            background-color: var(--v-primary-base);
            color: #fff !important;
        }
    }
}
@media (min-width:1200px){
}

</style>
