<template>
    <client-page>
        <sub-visual titOutlined="Our" tit="Solutions" sh="솔루션" tabActive="인프라" bg="/images/sub/visual/sv--solution.jpg" />
        <sub-section className="section--first section--last grey lighten-5">
            <sub-section small className="page-section--first">
                <h4 class="tit tit--sm tit--bar mb-6 mb-lg-12">
                    서버 및 주변기기
                </h4>
                <div class="mt-12 mt-lg-24">
                    <v-row class="row-cols-lg-5">
                        <v-col v-for="(partner, idx) in server?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                            <div class="border">
                                <div class="d-flex justify-center white">
                                    <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </sub-section>
            <sub-section small>                
                <h4 class="tit tit--sm tit--bar mb-6 mb-lg-12">
                    네트워크
                </h4>
                <div class="mt-12 mt-lg-24">
                    <v-row class="row-cols-lg-5">
                        <v-col v-for="(partner, idx) in network?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                            <div class="border">
                                <div class="d-flex justify-center white">
                                    <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </sub-section>
            <sub-section small>                
                <h4 class="tit tit--sm tit--bar mb-6 mb-lg-12">
                    상용소프트웨어
                </h4>
                <div class="mt-12 mt-lg-24">
                    <v-row class="row-cols-lg-5">
                        <v-col v-for="(partner, idx) in software?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                            <div class="border">
                                <div class="d-flex justify-center white">
                                    <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </sub-section>
        </sub-section>
        <!-- <section class="section-bg section ">
            <v-container>
                <div>
                    <div class="mb-lg-80 mb-40" data-aos-delay="150">
                        <h3 class="mb-24 font-weight-bold white--text">서버 및 주변기기</h3>
                        <v-row class="row-cols-lg-5">
                            <v-col v-for="partner, idx in server?.slice().reverse()" :key="idx" cols="6" sm="4" md="3"><v-img max-width="220" :aspect-ratio="220 / 80" :src="`${partner.image}`" alt="logo"/></v-col>
                        </v-row>
                    </div>
                    <div class="mb-lg-80 mb-40" data-aos-delay="200">
                        <h3 class="mb-24 font-weight-bold white--text">네트워크</h3>
                        <v-row class="row-cols-lg-5">
                            <v-col v-for="partner, idx in network?.slice().reverse()" :key="idx" cols="6" sm="4" md="3"><v-img max-width="220" :aspect-ratio="220 / 80" :src="`${partner.image}`" alt="logo"/></v-col>
                        </v-row>
                    </div>
                    <div class="mb-lg-80 mb-40" data-aos-delay="250">
                        <h3 class="mb-24 font-weight-bold white--text">상용소프트웨어</h3>
                        <v-row class="row-cols-lg-5">
                            <v-col v-for="partner, idx in software?.slice().reverse()" :key="idx" cols="6" sm="4" md="3"><v-img max-width="220" :aspect-ratio="220 / 80" :src="`${partner.image}`" alt="logo"/></v-col>
                        </v-row>
                    </div>
                </div>
            </v-container>
        </section> -->
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {
            server: [],
            network: [],
            software: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let server = await api.v1.banners.gets({ params: { code: "infra", name: "서버 및 주변기기" } });
            let network = await api.v1.banners.gets({ params: { code: "infra", name: "네트워크" } });
            let software = await api.v1.banners.gets({ params: { code: "infra", name: "상용소프트웨어" } });
            this.server = server.banner?.slides;
            this.network = network.banner?.slides;
            this.software = software.banner?.slides;
        },
    },
};
</script>
