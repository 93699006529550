var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('solution-sub-visual'), _c('solution-tab'), _c('sub-section', {
    attrs: {
      "tit": "Safe IP",
      "titTxt": "IP관리 솔루션",
      "className": "section--first"
    }
  }, [_c('tit-logo', {
    attrs: {
      "tit": "제품 개요",
      "logo": "/images/sub/solution/safeip/top-logo.jpg"
    }
  }, [_vm._v(" Safe IP는 내부 네트워크 사용 단말의 가시성을 확보하고, 네트워크 자원(IP/MAC) 관리를 중앙화 및 자동화하여 편리하게 사용할 수 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 있는 IP 관리 솔루션입니다. ")])], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "제품 구성도",
      "className": "grey lighten-5"
    }
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/safeip/safeip-img1.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/safeip/safeip-img1-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "특장점"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/safeip/safeip-icon1-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 효율적인 네트워크 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 자원 관리 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 네트워크 사용 단말 가시성 확보 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · IP/MAC 관리 중앙화/자동화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 편리한 IP 관리 정책 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 등록/수정/삭제 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/safeip/safeip-icon1-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 사용자 컬럼 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 설정 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 관리자 필요에 따라 사용자 정의 필드 설정 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 다중 IP선택 후 정보 일괄 입력 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/safeip/safeip-icon1-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 네트워크 장비 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 관리업무 전산화 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 고객 운영환경에 맞는 OUI ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 관리자 부주의로 인한 장애 발생 위험 최소화 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "04",
      "icon": "/images/sub/solution/safeip/safeip-icon1-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 관리자 운영 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 편의성 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 관리자 맞춤 동적 대시보드 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · IP/MAC 사용이력 감사 보고서 ")])])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "주요기능",
      "className": "grey lighten-5"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "01",
      "icon": "/images/sub/solution/safeip/safeip-icon2-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 네트워크 자동 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 정보 자동 수집 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "02",
      "icon": "/images/sub/solution/safeip/safeip-icon2-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" IP/MAC 고정 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 및 장비 보호 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "03",
      "icon": "/images/sub/solution/safeip/safeip-icon2-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" IP/MAC 사용 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 현황 관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "04",
      "icon": "/images/sub/solution/safeip/safeip-icon2-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 비인가 단말 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 사용 차단 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "05",
      "icon": "/images/sub/solution/safeip/safeip-icon2-5.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 비인가 네트워크 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 탐지 차단 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "06",
      "icon": "/images/sub/solution/safeip/safeip-icon2-6.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 비인가 DHCP "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 서버 탐지 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "07",
      "icon": "/images/sub/solution/safeip/safeip-icon2-7.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 동적 IP 관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "08",
      "icon": "/images/sub/solution/safeip/safeip-icon2-8.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 정책관리 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 보고서 ")])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "className": "section--last"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "도입분야",
      "className": "page-section--first"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "금융",
      "icon": "/images/sub/solution/safeip/safeip-icon3-1.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "공공",
      "icon": "/images/sub/solution/safeip/safeip-icon3-2.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "정보통신",
      "icon": "/images/sub/solution/safeip/safeip-icon3-3.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "기업",
      "icon": "/images/sub/solution/safeip/safeip-icon3-4.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "350"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "병원",
      "icon": "/images/sub/solution/safeip/safeip-icon3-5.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "400"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "교육",
      "icon": "/images/sub/solution/safeip/safeip-icon3-6.svg"
    }
  })], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "제품 인증"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" · GS 인증 ")])]), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "특허"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" · 자동 개인 아이피 주소 할당 대역에 대한 제어 시스템 및 방법 ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }