<template>
    <client-page>
        <sub-visual titOutlined="Contact" tit="Us" sh="상담문의" bg="/images/sub/visual/sv--contact.jpg" />
        <sub-section className="section--first section--last">
            <div class="tit-wrap">
                <h3 class="tit">
                    충분한 상담 후 견적까지 안내 받아보세요.
                </h3>
                <p class="tit-txt mt-12 mt-lg-20">
                    링크넷코리아의 각 솔루션 담당자가 문의 내용에 대하여 빠르게 회신하여 드립니다. <br>
                    문의 내용을 상세히 기재할수록 정확한 답변이 가능합니다.
                </p>
            </div>
            <form-mail/>
        </sub-section>        
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import FormMail from '@/components/client/form/form-mail.vue';

export default {
    components: {
        ClientPage,
        SubVisual,
        FormMail,
        SubSection,
    },
    data() {
        return {
        };
    },
};
</script>
