<template>
    <client-page>
        <solution-sub-visual />
        <solution-tab />
        <sub-section tit="i-oneNAC" titTxt="네트워크 접근통제 솔루션" className="section--first">
            <tit-logo tit="제품 개요" logo="/images/sub/solution/ionenac/top-logo.jpg">
                i-oneNAC은 내부 사용자 및 단말에 대한 인증을 강화하여 비인가 단말에 대한 네트워크 접근을 차단하고 정보자산을 보호하는 <br class="d-none d-lg-block">
                네트워크 접근통제 솔루션입니다.
            </tit-logo>
        </sub-section>
        <sub-section sectionTit="제품 구성도" className="grey lighten-5">
            <v-img src="/images/sub/solution/ionenac/ionenac-img1.jpg" class="d-none d-md-block w-100 border"  data-aos="fade-up" data-aos-delay="150"/>
            <v-img src="/images/sub/solution/ionenac/ionenac-img1-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border"  data-aos="fade-up" data-aos-delay="150"/>
        </sub-section>
        <sub-section sectionTit="특장점">
            <v-row no-gutters class="icon-cards cols-4">
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="01" icon="/images/sub/solution/ionenac/ionenac-icon1-1.svg">
                        <template slot="tit">
                            강력한 보안
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · Agent 기반의 ACL 통제기능으로 외부위협 차단 및 랜섬웨어 감염 방지
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="02" icon="/images/sub/solution/ionenac/ionenac-icon1-2.svg">
                        <template slot="tit">
                            유연한 구성
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 네트워크 운영 환경에 맞는 다양한 구성방식 지원(ARP, Mirror, Hybrid)
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="03" icon="/images/sub/solution/ionenac/ionenac-icon1-3.svg">
                        <template slot="tit">
                            검증된 성능
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 대규모 사이트 구축 경험
                            </li>
                            <li class="dot-text">
                                · 대규모 사이트 장기간 안정적 <br class="d-none d-lg-block">
                                서비스 제공 중
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="04" icon="/images/sub/solution/ionenac/ionenac-icon1-4.svg">
                        <template slot="tit">
                            편리한 관리
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 쿼리 기반 관리자 맞춤 동적 <br class="d-none d-lg-block">
                                대시보드
                            </li>
                            <li class="dot-text">
                                · 위젯 기반 대시보드 구성 지원
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section sectionTit="주요기능" className="grey lighten-5">
            <v-row no-gutters class="icon-cards cols-3">
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="01" icon="/images/sub/solution/ionenac/ionenac-icon2-1.svg">
                        <template slot="tit">
                            단말 및 사용자 인증
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="02" icon="/images/sub/solution/ionenac/ionenac-icon2-2.svg">
                        <template slot="tit">
                            접근통제 접근관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="03" icon="/images/sub/solution/ionenac/ionenac-icon2-3.svg">
                        <template slot="tit">
                            IP 관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="04" icon="/images/sub/solution/ionenac/ionenac-icon2-4.svg">
                        <template slot="tit">
                            보안 관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="05" icon="/images/sub/solution/ionenac/ionenac-icon2-5.svg">
                        <template slot="tit">
                            운영관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="06" icon="/images/sub/solution/ionenac/ionenac-icon2-6.svg">
                        <template slot="tit">
                            감사로그
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section className="section--last">
            <sub-section small sectionTit="도입분야" className="page-section--first">
                <v-row>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="150">
                        <icon-frame tit="금융" icon="/images/sub/solution/ionenac/ionenac-icon3-1.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="200">
                        <icon-frame tit="공공" icon="/images/sub/solution/ionenac/ionenac-icon3-2.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="250">
                        <icon-frame tit="정보통신" icon="/images/sub/solution/ionenac/ionenac-icon3-3.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="300">
                        <icon-frame tit="기업" icon="/images/sub/solution/ionenac/ionenac-icon3-4.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="350">
                        <icon-frame tit="병원" icon="/images/sub/solution/ionenac/ionenac-icon3-5.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="400">
                        <icon-frame tit="교육" icon="/images/sub/solution/ionenac/ionenac-icon3-6.svg" />
                    </v-col>
                </v-row>
            </sub-section>
            <sub-section small sectionTitSm sectionTit="제품 인증">
                <p class="page-text dot-text grey--text" data-aos="fade-up" data-aos-delay="150">
                    · CC인증(EAL2), GS인증, IPv6 Ready 인증
                </p>
            </sub-section>
            <sub-section small sectionTitSm sectionTit="특허">
                <p class="page-text dot-text grey--text mb-6 mb-lg-12" data-aos="fade-up" data-aos-delay="150">
                    · IEEE 802.1x 기반의 네트워크 보안 시스템 및 네트워크 보안 방법
                </p>
                <p class="page-text dot-text grey--text" data-aos="fade-up" data-aos-delay="200">
                    · 가상 802.1x 기반 네트워크 접근 제어 장치 및 네트워크 접근 제어 방법
                </p>
            </sub-section>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SolutionSubVisual from "@/components/client/solution/solution-sub-visual.vue";
import SolutionTab from "@/components/client/solution/solution-tab.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";
import IconFrame from "@/components/client/sub/icon-frame-primary.vue";

export default {
    components: {
        ClientPage,
        SolutionSubVisual,
        SolutionTab,
        SubSection,
        TitLogo,
        IconCard,
        IconFrame,
    },
    data() {
        return {
        };
    },
};
</script>
