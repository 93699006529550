<template>
    <client-page>
        <solution-sub-visual />
        <solution-tab />
        <sub-section tit="i-oneNet" titTxt="망연계 솔루션" className="section--first">
            <tit-logo tit="제품 개요" logo="/images/sub/solution/ionenet/top-logo.jpg">
                i-oneNet은 분리된 망 간 안전한 서비스 및 파일 연계 기능을 제공하는 솔루션입니다. 보안위협으로부터 내부망의 보안성을 높이고, <br class="d-none d-lg-block">
                동시에 업무 편의성까지 제공합니다.
            </tit-logo>
        </sub-section>
        <sub-section sectionTit="제품 구성도" className="grey lighten-5">
            <div class="mb-20 mb-lg-40">
                <tit-small tit="스트림" sc="분리된 망 환경에서 서버간 실시간 데이터 연계"  data-aos="fade-up" data-aos-delay="300"/>
                <v-img src="/images/sub/solution/ionenet/ionenet-img1.jpg" class="d-none d-md-block w-100 border" />
                <v-img src="/images/sub/solution/ionenet/ionenet-img1-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border"  data-aos="fade-up" data-aos-delay="300"/>
            </div>
            <div>
                <tit-small tit="파일전송" sc="분리된 망 환경에서 PC간 안전한 파일전송" />
                <v-img src="/images/sub/solution/ionenet/ionenet-img2.jpg" class="d-none d-md-block w-100 border"  data-aos="fade-up" data-aos-delay="300"/>
                <v-img src="/images/sub/solution/ionenet/ionenet-img2-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border"  data-aos="fade-up" data-aos-delay="300"/>
            </div>
        </sub-section>
        <sub-section sectionTit="특장점">
            <v-row no-gutters class="icon-cards cols-3">
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="01" icon="/images/sub/solution/ionenet/ionenet-icon1-1.svg">
                        <template slot="tit">
                            검증받은 솔루션
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · CC인증(EAL4), GS인증
                            </li>
                            <li class="dot-text">
                                · 클라우드 서비스 확인제 인증
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="02" icon="/images/sub/solution/ionenet/ionenet-icon1-2.svg">
                        <template slot="tit">
                            우수한 보안성
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 망연계 유일 멀티 백신엔진 탑재
                            </li>
                            <li class="dot-text">
                                · 망국정원 검증필 암호모듈, 전용프로토콜 적용
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" md="4">
                    <icon-card list num="03" icon="/images/sub/solution/ionenet/ionenet-icon1-3.svg">
                        <template slot="tit">
                            높은 호환성
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 다양한 사용자 Web/Client 인터페이스 지원
                            </li>
                            <li class="dot-text">
                                · 클라우드 환경에서 안전한 파일전송 지원
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section sectionTit="주요기능" className="grey lighten-5">
            <v-row no-gutters class="icon-cards cols-4">
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="01" icon="/images/sub/solution/ionenet/ionenet-icon2-1.svg">
                        <template slot="tit">
                            멀티백신으로 <br class="d-none d-lg-block">
                            악성코드 탐지
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="02" icon="/images/sub/solution/ionenet/ionenet-icon2-2.svg">
                        <template slot="tit">
                            전송파일 <br class="d-none d-lg-block">
                            승인/결재
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="03" icon="/images/sub/solution/ionenet/ionenet-icon2-3.svg">
                        <template slot="tit">
                            다양한 <br class="d-none d-lg-block">
                            사용자 환경
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="04" icon="/images/sub/solution/ionenet/ionenet-icon2-4.svg">
                        <template slot="tit">
                            클라우드 <br class="d-none d-lg-block">
                            환경지원
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="05" icon="/images/sub/solution/ionenet/ionenet-icon2-5.svg">
                        <template slot="tit">
                            편리한 <br class="d-none d-lg-block">
                            정책설정
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="06" icon="/images/sub/solution/ionenet/ionenet-icon2-6.svg">
                        <template slot="tit">
                            다양한 <br class="d-none d-lg-block">
                            유관시스템 연계
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="07" icon="/images/sub/solution/ionenet/ionenet-icon2-7.svg">
                        <template slot="tit">
                            메일 연계 <br class="d-none d-lg-block">
                            솔루션
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card num="08" icon="/images/sub/solution/ionenet/ionenet-icon2-8.svg">
                        <template slot="tit">
                            PCURL <br class="d-none d-lg-block">
                            이미지 디톡스
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section>
            <sub-section sectionTit="보안강화" small className="page-section--first">
                <tit-small tit="ZT i-oneNet" sc="안전하게 망연계 서비스를 사용하기 위해 Zero Trust i-oneNet 서비스 구현" />
                <v-img src="/images/sub/solution/ionenet/ionenet-img3.jpg" class="d-none d-md-block w-100 border" />
                <v-img src="/images/sub/solution/ionenet/ionenet-img3-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border" />
            </sub-section>
            <sub-section sectionTit="도입분야" small>
                <v-row>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="150">
                        <icon-frame tit="내/외부 연계" icon="/images/sub/solution/ionenet/ionenet-icon3-1.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="200">
                        <icon-frame tit="외부 인터넷연계" icon="/images/sub/solution/ionenet/ionenet-icon3-2.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="250">
                        <icon-frame tit="사용자 PC간 파일전송" icon="/images/sub/solution/ionenet/ionenet-icon3-3.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="300">
                        <icon-frame tit="내/외부 메일연계" icon="/images/sub/solution/ionenet/ionenet-icon3-4.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="350">
                        <icon-frame tit="스마트시티 통합플랫폼" icon="/images/sub/solution/ionenet/ionenet-icon3-5.svg" />
                    </v-col>
                    <v-col cols="6" md="4" lg="2" data-aos="fade-up" data-aos-delay="400">
                        <icon-frame tit="클라우드 망연계" icon="/images/sub/solution/ionenet/ionenet-icon3-6.svg" />
                    </v-col>
                </v-row>
            </sub-section>
            <sub-section small sectionTitSm sectionTit="제품 인증">
                <p class="page-text dot-text grey--text" data-aos="fade-up" data-aos-delay="150">
                    · CC인증(EAL4), GS인증, 보안기능확인서, SMPP성능인증, IPv6인증, 클라우드 서비스 확인제 인증
                </p>
            </sub-section>
        </sub-section>
        <sub-section sectionTit="제품 소개 영상" className="section--last grey lighten-5">
            <div class="iframe-wrap" data-aos="fade-up" data-aos-delay="150">
                <iframe src="https://www.youtube.com/embed/gDKC4oSkKRo" title="휴네시온 망연계 솔루션 i-oneNet" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SolutionSubVisual from "@/components/client/solution/solution-sub-visual.vue";
import SolutionTab from "@/components/client/solution/solution-tab.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";
import TitSmall from "@/components/client/sub/section-tit-sm.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";
import IconFrame from "@/components/client/sub/icon-frame-primary.vue";

export default {
    components: {
        ClientPage,
        SolutionSubVisual,
        SolutionTab,
        SubSection,
        TitLogo,
        TitSmall,
        IconCard,
        IconFrame,
    },
    data() {
        return {
        };
    },
};
</script>
