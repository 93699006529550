<template>
    <footer class="footer">
        <div class="footer-top">
            <v-container class="d-flex justify-space-between align-center">
                <ul class="footer-link">
                    <li><router-link to="/en/about/clients">
                        Introduction
                    </router-link></li>
                    <!-- <li><a href="https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=UEhBU0QwUmNLVTdUdVpDbDd0MUtKQT09" target="_blank">인재채용</a></li> -->
                    <!-- <li><router-link to="/provision">이용약관</router-link></li> -->
                    <!-- <li><router-link to="/privacy">개인정보취급방침</router-link></li> -->
                    <!-- <li><router-link to="/email-collect">이메일무단수집거부</router-link></li> -->
                    <li><router-link to="/en/about/location">
                        Location
                    </router-link></li>
                </ul>
                <ul class="footer-sns">
                    <li>
                        <a href="https://blog.naver.com/NBlogTop.naver?isHttpsRedirect=true&blogId=linknkor" target="_blank"><img src="/images/icon/blog.png" alt="blog" /></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/linknkor" target="_blank"><img src="/images/icon/facebook.png" alt="facebook" /></a>
                    </li>
                </ul>
            </v-container>
        </div>
        <div class="footer-bottom">
            <v-container class="d-flex justify-space-between align-center">
                <div class="">
                    <h3 class="font-play font-weight-bold primary--text mb-16">Link-Net Korea</h3>
                    <ul class="footer-info mb-16">
                        <li v-for="item in info" :key="item._id">
                            <span>{{ item.term }}</span> {{ item.desc }}
                        </li>
                        <li><span>CEO</span>Hong Daejin</li>
                        <li><span>Business Registration Number</span>891-86-00507</li>
                        <li><span>Tel</span>02-780-2939</li>
                        <li><span>Fax</span>02-785-2939</li>
                        <li><span>Email</span>sales@linknkor.co.kr</li>
                        <li><span>Address</span>#703, 704, 532, Yangcheon-ro, Gangseo-gu, Seoul, Republic of Korea</li>
                    </ul>
                    <p class="copyright">Copyright 2023. Link-Net Korea. All Rights Reserved.</p>
                </div>
                <div>
                    <u-scroll-top class="mt-md-auto ml-md-auto"></u-scroll-top>
                </div>
            </v-container>
        </div>
    </footer>
</template>

<script>
import UScrollTop from "@/components/dumb/u-scroll-top.vue";
import api from "@/api";

export default {
    components: {
        UScrollTop,
    },
    data() {
        return {
            setting: null,
        };
    },
    computed: {
        info() {
            return this.setting?.footer?.info || [];
        },
        sns() {
            return (this.setting?.footer?.sns || []).filter(({ shows }) => shows);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { setting } = await api.v1.setting.get();
            this.setting = setting;
        },
    },
};
</script>

<style scoped>
.footer {
    color: #959595;
    background-color: #111;
    /* padding: 40px 0; */
}
.footer .container {
    max-width: calc(var(--container) + 24px);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    /* .footer {
        padding: 60px 0;
    } */
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.footer-link {
    display: flex;
    flex-wrap: wrap;
    margin: -4px -10px;
}
.footer-link li {
    position: relative;
    padding: 4px 10px;
}
.footer-link li::before {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 1px;
    height: 8px;
    background-color: #fff;
    opacity: 0.15;
}
.footer-link li:first-child::before {
    display: none;
}
.footer-link li a {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
    color: #fff;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-link {
        margin: 0 -25px;
    }
    .footer-link li {
        padding: 0 25px;
    }
    .footer-link li::before {
        display: block;
        height: 10px;
    }
    .footer-link li a {
        font-size: 1.4rem;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.footer-info {
    font-size: 1.2rem;
    margin: -2px -8px;
    overflow: auto;
}
.footer-info::after {
    content: "";
    clear: both;
    display: table;
}
.footer-info li {
    float: left;
    display: inline-block;
    padding: 2px 8px;
    color: #999;
}
.footer-info li:nth-child(6) {
    clear: both;
}
.footer-info li > span {
    margin-right: 4px;
    color: #ddd;
}
.footer-info__tit {
    font-weight: 500;
    color: #fff;
    margin-bottom: 2px;
}
@media (min-width: 576px) {
    .footer-info {
        font-size: 1.4rem;
    }
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.copyright {
    font-size: 1.2rem;
    color: #959595;
}
.familysite {
    width: 200px;
    font-size: 1.4rem;
    border: 1px solid #fff;
    border-radius: 0;
}
.familysite .v-input__slot {
    height: 36px !important;
}
.familysite .v-text-field--outlined legend {
    border: none;
}
.v-application .familysite,
.familysite input::placeholder,
.familysite .v-select__selection--comma,
.familysite .mdi:before {
    color: #fff !important;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .copyright {
        font-size: 1.4rem;
    }
    .familysite .v-input__slot {
        height: 44px !important;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
