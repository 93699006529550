var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "color": "transparent",
      "rounded": "0",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-40"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('p', {
    staticClass: "font-play page-text font-weight-bold primary--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" " + _vm._s(_vm.num) + " ")]), _c('h5', {
    staticClass: "tit-txt word-keep-all"
  }, [_vm._t("tit")], 2)]), _c('v-col', {
    attrs: {
      "cols": "auto",
      "data-aos": "zoom-out",
      "data-aos-duration": "800",
      "data-aos-delay": "300"
    }
  }, [_c('v-img', {
    staticClass: "icon-card__icon",
    attrs: {
      "src": _vm.icon,
      "contain": ""
    }
  })], 1)], 1), _vm.list ? _c('ul', {
    staticClass: "mt-12 mt-lg-24 page-text dot-texts word-keep-all",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("list")], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }