<template>
    <v-row class="flex-column-reverse flex-md-row">
        <v-col cols="12" md="9">
            <h4 class="tit tit--sm tit--bar mb-6 mb-lg-12" data-aos="fade-up" data-aos-delay="100">
                {{tit}}
            </h4>
            <p class="page-text grey--text line-height-15 word-keep-all" data-aos="fade-up" data-aos-delay="150">
                <slot v-if="!list" />
            </p>
            <slot v-if="list" />
        </v-col>
        <v-col cols="12" md="3">
            <v-img :src="logo" contain class="w-100 mw-200px mw-md-280px mx-auto border"  data-aos="fade-up" data-aos-delay="200"/>
        </v-col>
    </v-row>
</template>

<script>

export default {
    props: {
        tit: { type: String, default: "" },
        logo: { type: String, default: "" },
        list: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
</style>
