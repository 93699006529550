<template>
    <client-page>
        <sub-visual titOutlined="Our" tit="Service" sh="서비스" bg="/images/sub/visual/sv--service.jpg" />
        <sub-section className="section--first">
            <h3 class="tit text-md-pre-wrap">
                {{ $t("service.title") }} <br class="d-none d-md-block" />
                <span class="primary--text">
                    {{ $t("service.titlePrimary") }}
                </span>
            </h3>
        </sub-section>
        <v-row no-gutters>
            <v-col cols="12" sm="6" data-aos="fade-right" data-aos-delay="100">
                <v-img src="/images/sub/service/service-img1.jpg" max-height="350" class="w-100 min-h-sm-100" />
            </v-col>
            <v-col cols="12" sm="6" class="grey lighten-5" data-aos="fade-left" data-aos-delay="100">
                <div class="pa-20 pa-md-30 pa-lg-50 pa-xl-80 d-flex align-center h-100">
                    <h5 class="tit-txt line-height-15 font-weight-regular text-xl-pre-wrap">{{ $t("service.text") }}</h5>
                </div>
            </v-col>
        </v-row>
        <sub-section sectionTitSm tit="serviceMaintenance.title" className="section--first">
            <sub-section small sectionTitSm sectionTit="serviceMaintenance.title" className="page-section--first">
                <p class="page-text grey--text line-height-15 word-keep-all mb-20 mb-lg-40" data-aos="fade-up" data-aos-delay="150">{{ $t("serviceMaintenance.text") }}</p>
                <div class="mb-20 mb-lg-40">
                    <v-row>
                        <v-col cols="12" sm="6" data-aos="fade-up" data-aos-delay="100">
                            <text-card tit="serviceMaintenance.list1" />
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-n14 mt-sm-0" data-aos="fade-up" data-aos-delay="150">
                            <text-card tit="serviceMaintenance.list2" />
                        </v-col>
                    </v-row>
                </div>
                <v-img :src="$t('serviceMaintenance.image')" class="d-none d-md-block w-100" data-aos="fade-up" data-aos-delay="200" />
                <v-img :src="$t('serviceMaintenance.imageMobile')" max-width="702" class="d-block d-md-none w-100 mx-auto" data-aos="fade-up" data-aos-delay="200" />
            </sub-section>
            <sub-section small sectionTit="servicePurpose.title">
                <v-row>
                    <v-col cols="12" md="4" data-aos="fade-up" data-aos-delay="100">
                        <text-card tit="servicePurpose.list1" />
                    </v-col>
                    <v-col cols="12" md="4" class="mt-n14 mt-md-0" data-aos="fade-up" data-aos-delay="150">
                        <text-card tit="servicePurpose.list2" />
                    </v-col>
                    <v-col cols="12" md="4" class="mt-n14 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                        <text-card tit="servicePurpose.list3" />
                    </v-col>
                </v-row>
            </sub-section>
        </sub-section>

        <sub-section className="section--first grey lighten-5">
            <div class="tit-wrap text-center">
                <h3 class="tit" data-aos="fade-down" data-aos-delay="100">
                    컨설팅 서비스
                </h3>
                <p class="tit-txt mt-12 mt-lg-20" data-aos="fade-up" data-aos-delay="150">
                    Link-net Korea는 경험이 풍부한 컨설턴트를 중심으로 고객사의 업무특성 및 현황을 정확히 이해하고 <br class="d-none d-md-block">
                    최적화된 정보보호관리체계를 구축/운영하는 것으로써. 체계적인 컨설팅을 수행합니다.
                </p>
            </div>
            <v-row no-gutters class="icon-cards cols-3">
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="01" icon="/images/sub/service/service-icon1-1.svg">
                        <template slot="tit">
                            기술적 취약점 진단 <br class="d-none d-lg-block">
                            컨설팅 서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="02" icon="/images/sub/service/service-icon1-2.svg">
                        <template slot="tit">
                            ISO27001 / KISA ISMS <br class="d-none d-lg-block">
                            인증 컨설팅 서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="03" icon="/images/sub/service/service-icon1-3.svg">
                        <template slot="tit">
                            정보보호체계 구축 취약점 <br class="d-none d-lg-block">
                            진단 서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="04" icon="/images/sub/service/service-icon1-4.svg">
                        <template slot="tit">
                            보안감사 서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="05" icon="/images/sub/service/service-icon1-5.svg">
                        <template slot="tit">
                            내/외부 모의해킹 서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="06" icon="/images/sub/service/service-icon1-6.svg">
                        <template slot="tit">
                            어플리케이션 진단 서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="07" icon="/images/sub/service/service-icon1-7.svg">
                        <template slot="tit">
                            개인정보보호 / ISMS-P <br class="d-none d-lg-block">
                            인증 컨설팅 서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="08" icon="/images/sub/service/service-icon1-8.svg">
                        <template slot="tit">
                            내부정보 유출방지 컨설팅 <br class="d-none d-lg-block">
                            서비스
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" md="4">
                    <icon-card num="09" icon="/images/sub/service/service-icon1-9.svg">
                        <template slot="tit">
                            장기보안(ON going) <br class="d-none d-lg-block">
                            서비스
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>

        <sub-section class="section--first">
            <h3 class="tit text-center" data-aos="fade-down" data-aos-delay="100">{{ $t("serviceSI.title") }}</h3>
            <p class="tit-txt mt-12 mt-lg-20 text-center text-md-pre-wrap" data-aos="fade-up" data-aos-delay="150">{{ $t("serviceSI.text") }}</p>
        </sub-section>
        <div class="service-banner image-card" style="background-image: url(/images/sub/service/service-banner1.jpg)">
            <v-layout align-center class="h-100">
                <v-container>
                    <v-row align="center" class="flex-md-row-reverse">
                        <v-col cols="12" md="auto">
                            <v-img src="/images/sub/service/service-icon2.svg" contain max-width="100" class="w-60px w-lg-100px" data-aos="zoom-out" data-aos-delay="400" />
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" md="auto" data-aos="fade-up" data-aos-delay="100">
                            <h3 class="tit mb-12 mb-lg-20">{{ $t("serviceSI.public") }}</h3>
                            <p class="tit-txt text-md-pre-wrap">{{ $t("serviceSI.publicText") }}</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-layout>
        </div>
        <div class="service-banner image-card" style="background-image: url(/images/sub/service/service-banner2.jpg)">
            <v-layout align-center class="h-100">
                <v-container>
                    <v-row align="center" class="text-md-right">
                        <v-col cols="12" md="auto">
                            <v-img src="/images/sub/service/service-icon3.svg" contain max-width="100" class="w-60px w-lg-100px" data-aos="zoom-out" data-aos-delay="400" />
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" md="auto" data-aos="fade-up" data-aos-delay="100">
                            <h3 class="tit mb-12 mb-lg-20">{{ $t("serviceSI.finance") }}</h3>
                            <p class="tit-txt text-md-pre-wrap">{{ $t("serviceSI.financeText") }}</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-layout>
        </div>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TextCard from "@/components/client/sub/text-card.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        TextCard,
        IconCard,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.service-banner {
    padding: 40px 0;
    color: #ffff;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .service-banner {
        height: 400px;
    }
}
@media (min-width: 1024px) {
    .service-banner {
        height: 600px;
    }
}
@media (min-width: 1200px) {
}
</style>
