var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('solution-sub-visual'), _c('solution-tab'), _c('sub-section', {
    attrs: {
      "tit": "i-oneNet",
      "titTxt": "망연계 솔루션",
      "className": "section--first"
    }
  }, [_c('tit-logo', {
    attrs: {
      "tit": "제품 개요",
      "logo": "/images/sub/solution/ionenet/top-logo.jpg"
    }
  }, [_vm._v(" i-oneNet은 분리된 망 간 안전한 서비스 및 파일 연계 기능을 제공하는 솔루션입니다. 보안위협으로부터 내부망의 보안성을 높이고, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 동시에 업무 편의성까지 제공합니다. ")])], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "제품 구성도",
      "className": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('tit-small', {
    attrs: {
      "tit": "스트림",
      "sc": "분리된 망 환경에서 서버간 실시간 데이터 연계",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }), _c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/ionenet/ionenet-img1.jpg"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/ionenet/ionenet-img1-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  })], 1), _c('div', [_c('tit-small', {
    attrs: {
      "tit": "파일전송",
      "sc": "분리된 망 환경에서 PC간 안전한 파일전송"
    }
  }), _c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/ionenet/ionenet-img2.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/ionenet/ionenet-img2-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  })], 1)]), _c('sub-section', {
    attrs: {
      "sectionTit": "특장점"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/ionenet/ionenet-icon1-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 검증받은 솔루션 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · CC인증(EAL4), GS인증 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 클라우드 서비스 확인제 인증 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/ionenet/ionenet-icon1-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 우수한 보안성 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 망연계 유일 멀티 백신엔진 탑재 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 망국정원 검증필 암호모듈, 전용프로토콜 적용 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/ionenet/ionenet-icon1-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 높은 호환성 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 다양한 사용자 Web/Client 인터페이스 지원 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 클라우드 환경에서 안전한 파일전송 지원 ")])])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "주요기능",
      "className": "grey lighten-5"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "01",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 멀티백신으로 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 악성코드 탐지 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "02",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 전송파일 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 승인/결재 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "03",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 다양한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 사용자 환경 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "04",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 클라우드 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 환경지원 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "05",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-5.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 편리한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 정책설정 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "06",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-6.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 다양한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 유관시스템 연계 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "07",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-7.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 메일 연계 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 솔루션 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "08",
      "icon": "/images/sub/solution/ionenet/ionenet-icon2-8.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" PCURL "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 이미지 디톡스 ")])], 2)], 1)], 1)], 1), _c('sub-section', [_c('sub-section', {
    attrs: {
      "sectionTit": "보안강화",
      "small": "",
      "className": "page-section--first"
    }
  }, [_c('tit-small', {
    attrs: {
      "tit": "ZT i-oneNet",
      "sc": "안전하게 망연계 서비스를 사용하기 위해 Zero Trust i-oneNet 서비스 구현"
    }
  }), _c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/ionenet/ionenet-img3.jpg"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/ionenet/ionenet-img3-mo.jpg",
      "max-width": "702"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "도입분야",
      "small": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "내/외부 연계",
      "icon": "/images/sub/solution/ionenet/ionenet-icon3-1.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "외부 인터넷연계",
      "icon": "/images/sub/solution/ionenet/ionenet-icon3-2.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "사용자 PC간 파일전송",
      "icon": "/images/sub/solution/ionenet/ionenet-icon3-3.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "내/외부 메일연계",
      "icon": "/images/sub/solution/ionenet/ionenet-icon3-4.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "350"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "스마트시티 통합플랫폼",
      "icon": "/images/sub/solution/ionenet/ionenet-icon3-5.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "400"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "클라우드 망연계",
      "icon": "/images/sub/solution/ionenet/ionenet-icon3-6.svg"
    }
  })], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "제품 인증"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" · CC인증(EAL4), GS인증, 보안기능확인서, SMPP성능인증, IPv6인증, 클라우드 서비스 확인제 인증 ")])])], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "제품 소개 영상",
      "className": "section--last grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "iframe-wrap",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('iframe', {
    attrs: {
      "src": "https://www.youtube.com/embed/gDKC4oSkKRo",
      "title": "휴네시온 망연계 솔루션 i-oneNet",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      "allowfullscreen": ""
    }
  })])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }