var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-tabs-wrap mt-40 mt-md-60 mt-lg-80"
  }, [_c('v-container', [_c('v-tabs', {
    staticClass: "v-tabs--primary",
    attrs: {
      "hide-slider": "",
      "height": "auto"
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/solution/security/i-oneNet"
    }
  }, [_vm._v("i-oneNet")]), _c('v-tab', {
    attrs: {
      "to": "/solution/security/i-oneNAC"
    }
  }, [_vm._v("i-oneNAC")]), _c('v-tab', {
    attrs: {
      "to": "/solution/security/SafeIP"
    }
  }, [_vm._v("Safe IP")]), _c('v-tab', {
    attrs: {
      "to": "/solution/security/NGS"
    }
  }, [_vm._v("NGS")]), _c('v-tab', {
    attrs: {
      "to": "/solution/security/i-oneJTac"
    }
  }, [_vm._v("i-oneJTac")]), _c('v-tab', {
    attrs: {
      "to": "/solution/security/mSABER"
    }
  }, [_vm._v("mSABER")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }