var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Company",
      "tit": "Introduction",
      "sh": "회사소개",
      "tabActive": "연혁",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--location section--first section--last"
    }
  }, [_c('v-img', {
    staticClass: "w-100 mb-40 mb-md-0",
    attrs: {
      "src": "/images/sub/about/history/history-img.jpg"
    }
  }), _c('div', {
    staticClass: "history--group"
  }, _vm._l(_vm.historyList, function (history, idx) {
    return _c('v-row', {
      key: idx,
      staticClass: "history",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "history__inner px-md-20 px-lg-40"
    }, [_c('h3', {
      staticClass: "tit line-height-1 font-play mb-20 mb-lg-40",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "150"
      }
    }, [_vm._v(" " + _vm._s(history.subject) + " ")]), _c('ul', {
      staticClass: "dot-texts",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, _vm._l(history.contents, function (content, idx) {
      return _c('li', {
        key: idx,
        staticClass: "dot-text page-text grey--text"
      }, [_vm._v(" · " + _vm._s(content.detail) + " ")]);
    }), 0)])])], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }