<template>
    <sub-visual titOutlined="Our" tit="Solutions" sh="솔루션" tabActive="보안솔루션" bg="/images/sub/visual/sv--solution.jpg" />
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";

export default {
    components: {
        SubVisual,
    },
    props: {
    },
};
</script>

<style lang="scss" scoped>
</style>
