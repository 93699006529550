<template>
    <v-card elevation="0" outlined class="h-100 border-top-0">
        <v-img :src="image" class="image-card__image"  data-aos="fade-up" data-aos-delay="150"/>
        <div class="border-top pa-20 pa-md-24">
            <h5 class="tit-txt mb-6 mb-lg-12" data-aos="fade-up" data-aos-delay="200">
                {{tit}}
            </h5>
            <ul class="dot-texts page-text grey--text" data-aos="fade-up" data-aos-delay="250">
                <slot />
            </ul>
        </div>
    </v-card>
</template>

<script>

export default {
    props: {
        image: { type: String, default: "" },
        tit: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
.image-card__image{
    margin: 0 -1px;
    max-width: unset;
}
@media (min-width:576px){
}
@media (min-width:768px){    
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
