var render = function render(){
  var _vm$server, _vm$network, _vm$software;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Our",
      "tit": "Solutions",
      "sh": "솔루션",
      "tabActive": "인프라",
      "bg": "/images/sub/visual/sv--solution.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--first section--last grey lighten-5"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "className": "page-section--first"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm tit--bar mb-6 mb-lg-12"
  }, [_vm._v(" 서버 및 주변기기 ")]), _c('div', {
    staticClass: "mt-12 mt-lg-24"
  }, [_c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$server = _vm.server) === null || _vm$server === void 0 ? void 0 : _vm$server.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1)]), _c('sub-section', {
    attrs: {
      "small": ""
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm tit--bar mb-6 mb-lg-12"
  }, [_vm._v(" 네트워크 ")]), _c('div', {
    staticClass: "mt-12 mt-lg-24"
  }, [_c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$network = _vm.network) === null || _vm$network === void 0 ? void 0 : _vm$network.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1)]), _c('sub-section', {
    attrs: {
      "small": ""
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm tit--bar mb-6 mb-lg-12"
  }, [_vm._v(" 상용소프트웨어 ")]), _c('div', {
    staticClass: "mt-12 mt-lg-24"
  }, [_c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$software = _vm.software) === null || _vm$software === void 0 ? void 0 : _vm$software.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }