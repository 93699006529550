var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Company",
      "tit": "Introduction",
      "sh": "회사소개",
      "tabActive": "오시는 길",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--location section--first section--last"
    }
  }, [_c('div', {
    staticClass: "mb-40 mb-md-60 mb-lg-80"
  }, [_c('v-img', {
    staticClass: "mb-20 mb-lg-40",
    attrs: {
      "src": "/images/sub/about/location/logo.jpg",
      "contain": "",
      "max-height": "160"
    }
  }), _c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('div', {
    staticClass: "icon-wrap mr-6 mr-lg-12"
  }, [_c('i', {
    staticClass: "icon icon-point"
  })]), _c('h4', {
    staticClass: "tit--sm"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " ")])])], 1), _c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.8510271216633!2d126.8570440042164!3d37.55857347697548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9c05fb44fa43%3A0xb8e100e3dd19bda4!2z6rCA7JaRIOuNlOumrOu4jOyVhOuEiOu5hOymiO2DgOybjA!5e0!3m2!1sko!2skr!4v1719795876255!5m2!1sko!2skr",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  }), _c('v-card', {
    staticClass: "loctaion-info",
    attrs: {
      "elevation": "5"
    }
  }, [_c('div', {
    staticClass: "py-20 py-md-30 py-lg-40"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "border-md-end mb-10 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-layout', {
    staticClass: "justify-md-center px-20 px-md-0",
    attrs: {
      "align-center": "",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('div', {
    staticClass: "icon-wrap mr-6 mr-lg-12"
  }, [_c('i', {
    staticClass: "icon icon-tel"
  })]), _c('a', {
    staticClass: "tit-txt font-weight-bold",
    attrs: {
      "href": "tel:02-780-2939"
    }
  }, [_vm._v(" 02-780-2939 ")])])], 1), _c('v-col', {
    staticClass: "border-md-end mb-10 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('v-layout', {
    staticClass: "justify-md-center px-20 px-md-0",
    attrs: {
      "align-center": ""
    }
  }, [_c('div', {
    staticClass: "icon-wrap mr-6 mr-lg-12"
  }, [_c('i', {
    staticClass: "icon icon-fax"
  })]), _c('h5', {
    staticClass: "tit-txt font-weight-bold"
  }, [_vm._v(" 02-785-2939 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-layout', {
    staticClass: "justify-md-center px-20 px-md-0",
    attrs: {
      "align-center": "",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('div', {
    staticClass: "icon-wrap mr-6 mr-lg-12"
  }, [_c('i', {
    staticClass: "icon icon-mail"
  })]), _c('a', {
    staticClass: "tit-txt font-weight-bold",
    attrs: {
      "href": "mailto:sales@linknkor.co.kr"
    }
  }, [_vm._v(" sales@linknkor.co.kr ")])])], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }