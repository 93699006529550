var render = function render(){
  var _vm$government, _vm$finance, _vm$etc;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Company",
      "tit": "Introduction",
      "sh": "회사소개",
      "tabActive": "고객사",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--first section--last grey lighten-5"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "className": "page-section--first"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm tit--bar mb-6 mb-lg-12"
  }, [_vm._v(" " + _vm._s(_vm.$t("공공기관")) + " / " + _vm._s(_vm.$t("지자체")) + " ")]), _c('div', {
    staticClass: "mt-12 mt-lg-24"
  }, [_c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$government = _vm.government) === null || _vm$government === void 0 ? void 0 : _vm$government.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1)]), _c('sub-section', {
    attrs: {
      "small": ""
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm tit--bar mb-6 mb-lg-12"
  }, [_vm._v(" " + _vm._s(_vm.$t("금융")) + " ")]), _c('div', {
    staticClass: "mt-12 mt-lg-24"
  }, [_c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$finance = _vm.finance) === null || _vm$finance === void 0 ? void 0 : _vm$finance.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1)]), _c('sub-section', {
    attrs: {
      "small": ""
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm tit--bar mb-6 mb-lg-12"
  }, [_vm._v(" " + _vm._s(_vm.$t("일반기업")) + " / " + _vm._s(_vm.$t("기타")) + " ")]), _c('div', {
    staticClass: "mt-12 mt-lg-24"
  }, [_c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$etc = _vm.etc) === null || _vm$etc === void 0 ? void 0 : _vm$etc.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }