<template>
    <client-page>
        <sub-visual titOutlined="Our" tit="Service" sh="Service" bg="/images/sub/visual/sv--service.jpg" />
        <sub-section className="section--first">
            <h3 class="tit text-md-pre-wrap">
                Link-Net Korea puts customer value first <br class="d-none d-md-block" />
                <span class="primary--text"> and delivers customer satisfaction </span>
            </h3>
        </sub-section>
        <v-row no-gutters>
            <v-col cols="12" sm="6" data-aos="fade-right" data-aos-delay="100">
                <v-img src="/images/sub/service/service-img1.jpg" max-height="350" class="w-100 min-h-sm-100" />
            </v-col>
            <v-col cols="12" sm="6" class="grey lighten-5" data-aos="fade-left" data-aos-delay="100">
                <div class="pa-20 pa-md-30 pa-lg-50 pa-xl-80 d-flex align-center h-100">
                    <h5 class="tit-txt line-height-15 font-weight-regular text-xl-pre-wrap">
                        In order to immediately respond to changes in the public <br class="d-none d-xl-block" />
                        and corporate management environment, Link-Net Korea <br class="d-none d-xl-block" />
                        works with experienced experts to derive requirements <br class="d-none d-xl-block" />
                        from the customer's perspective and provides customized <br class="d-none d-xl-block" />
                        services by combining knowledge accumulated from <br class="d-none d-xl-block" />
                        numerous SI projects. Improves satisfaction.
                    </h5>
                </div>
            </v-col>
        </v-row>
        <sub-section sectionTitSm tit="Integrated maintenance" className="section--first">
            <sub-section small sectionTitSm sectionTit="Integrated maintenance" className="page-section--first">
                <p class="page-text grey--text line-height-15 word-keep-all mb-20 mb-lg-40" data-aos="fade-up" data-aos-delay="150">We will provide optimal Total IT Service in the areas of system integration, outsourcing services, solutions and consulting, and specialized services.</p>
                <div class="mb-20 mb-lg-40">
                    <v-row>
                        <v-col cols="12" sm="6" data-aos="fade-up" data-aos-delay="100">
                            <text-card tit="Application/Infrastructure/Network Outsourcing" />
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-n14 mt-sm-0" data-aos="fade-up" data-aos-delay="150">
                            <text-card tit="Integrated maintenance and consignment operation management service" />
                        </v-col>
                    </v-row>
                </div>
                <v-img src="/images/sub/service/en/service-img2.jpg" class="d-none d-md-block w-100" data-aos="fade-up" data-aos-delay="200" />
                <v-img src="/images/sub/service/en/service-img2-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto" data-aos="fade-up" data-aos-delay="200" />
            </sub-section>
            <sub-section small sectionTit="Maintenance purpose">
                <v-row>
                    <v-col cols="12" md="4" data-aos="fade-up" data-aos-delay="100">
                        <text-card tit="Optimization of communication network through periodic verification and analysis of communication network" />
                    </v-col>
                    <v-col cols="12" md="4" class="mt-n14 mt-md-0" data-aos="fade-up" data-aos-delay="150">
                        <text-card tit="Eliminate obstacles in advance through preventive maintenance" />
                    </v-col>
                    <v-col cols="12" md="4" class="mt-n14 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                        <text-card tit="Realizing customer satisfaction through rapid response to new transfers and troubleshooting" />
                    </v-col>
                </v-row>
            </sub-section>
        </sub-section>

        <sub-section>
            <h3 class="tit text-center" data-aos="fade-down" data-aos-delay="100">SI Business</h3>
            <p class="tit-txt mt-12 mt-lg-20 text-center text-md-pre-wrap" data-aos="fade-up" data-aos-delay="150">Link-net Korea improves customer satisfaction by deriving requirements from the customer's perspective and providing services that combine knowledge accumulated from many projects with experienced professionals.</p>
        </sub-section>
        <div class="service-banner image-card" style="background-image: url(/images/sub/service/service-banner1.jpg)">
            <v-layout align-center class="h-100">
                <v-container>
                    <v-row align="center" class="flex-md-row-reverse">
                        <v-col cols="12" md="auto">
                            <v-img src="/images/sub/service/service-icon2.svg" contain max-width="100" class="w-60px w-lg-100px" data-aos="zoom-out" data-aos-delay="400" />
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" md="auto" data-aos="fade-up" data-aos-delay="100">
                            <h3 class="tit mb-12 mb-lg-20">Public SI Service</h3>
                            <p class="tit-txt text-md-pre-wrap">
                                We achieve customer satisfaction through development, infrastructure, and security system construction <br class="d-none d-md-block" />
                                for administrative agencies, railroad corporations, and subway corporations that provide services to the public.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-layout>
        </div>
        <div class="service-banner image-card" style="background-image: url(/images/sub/service/service-banner2.jpg)">
            <v-layout align-center class="h-100">
                <v-container>
                    <v-row align="center" class="text-md-right">
                        <v-col cols="12" md="auto">
                            <v-img src="/images/sub/service/service-icon3.svg" contain max-width="100" class="w-60px w-lg-100px" data-aos="zoom-out" data-aos-delay="400" />
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" md="auto" data-aos="fade-up" data-aos-delay="100">
                            <h3 class="tit mb-12 mb-lg-20">Financial SI service</h3>
                            <p class="tit-txt text-md-pre-wrap">
                                Financial services are rapidly transitioning to a financial environment that incorporates non-face-to-face, <br class="d-none d-md-block" />
                                digital finance and new technologies following the Corona pandemic. We seek to complete safe financial business <br class="d-none d-md-block" />
                                by establishing the infrastructure and security systems required by financial institutions and related organizations.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-layout>
        </div>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TextCard from "@/components/client/sub/text-card.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        TextCard,
        IconCard,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.service-banner {
    padding: 40px 0;
    color: #ffff;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .service-banner {
        height: 400px;
    }
}
@media (min-width: 1024px) {
    .service-banner {
        height: 600px;
    }
}
@media (min-width: 1200px) {
}
</style>
