<template>
    <client-page>
        <sub-visual titOutlined="Company" tit="Introduction" sh="회사소개" tabActive="회사소개" bg="/images/sub/visual/sv--about.jpg" />
        <sub-section className="section--first section--last">
            <h3 class="tit mb-90 mb-md-80 mb-lg-120 word-keep-all">
                링크넷코리아를 방문해 주신 모든 분들께 <br>
                <span class="primary--text">진심으로 감사드립니다.</span>
            </h3>
            <div class="about-banner">
                <div class="about-banner__logo">
                    <v-img src="/images/sub/about/about/logo.jpg" class="w-100 " data-aos="fade-left" data-aos-delay="200"/>
                </div>
                <v-img src="/images/sub/about/about/banner.jpg" class="about-banner__inner"/>
            </div>
            <div class="mt-40 mt-lg-60">
                <p class="tit-txt line-height-15 mb-6 mb-lg-12">
                    링크넷코리아는 보다 안전하고 풍요로운 인터넷 네트워크 환경을 제공하기 위해, 최고의 기술진과컨설턴트들이 모여 만든 젊은 회사입니다.
                </p>
                <p class="tit-txt line-height-15">
                    정부기관, 관공서, 국방등 주요정보통신시설과 은행, 증권사, 캐피탈등 금융권과 민간기업의 수많은 프로젝트 수행을 통해 안전한 망분리와 망연계, <br class="d-none d-lg-block">
                    시스템접근제어, 통합로그인을 지원하는 FIDO 기반의 생체인증솔루션을 판매, 구축하고 통합유지보수 하고 있습니다.
                </p>
            </div>
        </sub-section>     
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {
        };
    },
};
</script>

<style lang="scss" scoped>
.about-banner{
    width: 100%;
    position: relative;
    &__logo{
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        z-index: 2;
        max-width: 160px;
        .v-image{
            box-shadow: var(--shadow);
        }
    }
    &__inner{
        position: relative;
        width: 100vw;
        height: 160px;
        max-width: 100vw;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .about-banner{
        &__logo{
            max-width: 260px;
        }
        &__inner{
            height: 200px;
        }
    }
}
@media (min-width:1024px){
    .about-banner{
        &__logo{
            max-width: 382px;
        }
        &__inner{
            height: 350px;
        }
    }    
}
@media (min-width:1200px){
}

</style>
