<template>
    <div class="text-center">
        <icon-frame :icon="icon" class="mb-12 mb-lg-24"  data-aos="fade-up" data-aos-delay="150"/>
        <v-progress-linear height="2" :value="width" class="mb-12 mb-lg-24" data-aos="fade-up" data-aos-delay="200"/>
        <p class="font-play page-text font-weight-bold primary--text line-height-1 mb-4 mb-lg-8" data-aos="fade-up" data-aos-delay="250">
            step {{step}}
        </p>
        <p class="page-text word-keep-all" data-aos="fade-up" data-aos-delay="300">
            <slot />
        </p>
    </div>
</template>

<script>
import IconFrame from "@/components/client/sub/icon-frame.vue";

export default {
    components: {
        IconFrame,
    },
    props: {
        step: { type: String, default: "" },
        icon: { type: String, default: "" },
        width: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-progress-linear__background.primary{
    opacity: 1;
    background-color: #d0d4de !important;
}
</style>
