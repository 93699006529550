var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('solution-sub-visual'), _c('solution-tab'), _c('sub-section', {
    attrs: {
      "tit": "NGS",
      "titTxt": "시스템 접근제어 및 계정관리 솔루션",
      "className": "section--first"
    }
  }, [_c('tit-logo', {
    attrs: {
      "tit": "제품 개요",
      "logo": "/images/sub/solution/ngs/top-logo.jpg"
    }
  }, [_vm._v(" NGS는 내부시스템에 접속하는 경로를 일원화하여 시스템 접근제어와 안전한 계정관리 및 패스워드 관리를 한번에 제공하는 3in1 "), _c('br', {
    staticClass: "d-none d-lg-blockk"
  }), _vm._v(" 솔루션입니다. ")])], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "제품 구성도",
      "className": "grey lighten-5"
    }
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/ngs/ngs-img1.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/ngs/ngs-img1-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "특장점"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/ngs/ngs-icon1-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 3in1 통합 관리 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 시스템 접근제어, 계정관리, 패스워드 관리 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 통합제공 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/ngs/ngs-icon1-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 보안성 강화 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 시스템 접속 경로 일원화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 시스템 우회접속 탐지 및 차단 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/ngs/ngs-icon1-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 운영 편의성 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 시스템 계정/패스워드 관리 자동화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 실시간 모니터링 및 이력 보고서 제공 ")])])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "주요기능",
      "className": "grey lighten-5"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "01",
      "icon": "/images/sub/solution/ngs/ngs-icon2-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 접근 권한 통제 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "02",
      "icon": "/images/sub/solution/ngs/ngs-icon2-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 접속 세션 통제 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "03",
      "icon": "/images/sub/solution/ngs/ngs-icon2-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 로그 감사 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "04",
      "icon": "/images/sub/solution/ngs/ngs-icon2-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 사용자 인증 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "05",
      "icon": "/images/sub/solution/ngs/ngs-icon2-5.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 보안 취약점 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 점검 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "06",
      "icon": "/images/sub/solution/ngs/ngs-icon2-6.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 계정 및 패스워드 관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "07",
      "icon": "/images/sub/solution/ngs/ngs-icon2-7.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" DBMS 접근 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 통제 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "08",
      "icon": "/images/sub/solution/ngs/ngs-icon2-8.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 모니터링 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 보고서 ")])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "className": "section--last"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "도입분야",
      "className": "page-section--first"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "국방",
      "icon": "/images/sub/solution/ngs/ngs-icon3-1.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "공공",
      "icon": "/images/sub/solution/ngs/ngs-icon3-2.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "클라우드 접근제어·계정관리",
      "icon": "/images/sub/solution/ngs/ngs-icon3-3.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "기업",
      "icon": "/images/sub/solution/ngs/ngs-icon3-4.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "350"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "금융·병원",
      "icon": "/images/sub/solution/ngs/ngs-icon3-5.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "400"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "교육",
      "icon": "/images/sub/solution/ngs/ngs-icon3-6.svg"
    }
  })], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "제품 인증"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" · CC인증(EAL3), GS인증 ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }