var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Our",
      "tit": "Solutions",
      "sh": "솔루션",
      "tabActive": "클라우드",
      "bg": "/images/sub/visual/sv--solution.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "tit": "오케스트로",
      "titTxt": "클라우드 관리 플랫폼",
      "className": "section--first section--last"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "className": "page-section--first"
    }
  }, [_c('tit-logo', {
    attrs: {
      "tit": "제품 개요",
      "logo": "/images/sub/solution/cloud/top-logo.jpg"
    }
  }, [_vm._v(" 오케스트로에서 제공하고 있는 최적의 솔루션 Full Stack을 통해 클라우드 환경의 인프라에서 운영관리까지의 전 범위를 지원합니다. ")])], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "제품 구성도"
    }
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/cloud/cloud-img1.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/cloud/cloud-img1-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }