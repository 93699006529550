<template>
    <div class="mb-12 mb-lg-20">
        <v-row align="center" class="row--small">
            <v-col cols="12" md="auto">
                <h5 class="tit-txt" data-aos="fade-up" data-aos-delay="100">
                    {{tit}}
                </h5>
            </v-col>
            <v-col cols="auto" class="d-none d-md-block"> 
                <v-layout>
                    <v-divider vertical class="v-divider--vertical--sm grey lighten-3"  data-aos="fade-up" data-aos-delay="150"/>
                </v-layout>
            </v-col>
            <v-col cols="12" md="" class="mt-n4 mt-md-0">
                <p class="page-text grey--text" data-aos="fade-up" data-aos-delay="200">
                    {{sc}}
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    props: {
        tit: { type: String, default: "" },
        sc: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
</style>
