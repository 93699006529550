var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Company",
      "tit": "Introduction",
      "sh": "회사소개",
      "tabActive": "회사소개",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--first section--last"
    }
  }, [_c('h3', {
    staticClass: "tit mb-90 mb-md-80 mb-lg-120 word-keep-all"
  }, [_vm._v(" 링크넷코리아를 방문해 주신 모든 분들께 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("진심으로 감사드립니다.")])]), _c('div', {
    staticClass: "about-banner"
  }, [_c('div', {
    staticClass: "about-banner__logo"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/about/about/logo.jpg",
      "data-aos": "fade-left",
      "data-aos-delay": "200"
    }
  })], 1), _c('v-img', {
    staticClass: "about-banner__inner",
    attrs: {
      "src": "/images/sub/about/about/banner.jpg"
    }
  })], 1), _c('div', {
    staticClass: "mt-40 mt-lg-60"
  }, [_c('p', {
    staticClass: "tit-txt line-height-15 mb-6 mb-lg-12"
  }, [_vm._v(" 링크넷코리아는 보다 안전하고 풍요로운 인터넷 네트워크 환경을 제공하기 위해, 최고의 기술진과컨설턴트들이 모여 만든 젊은 회사입니다. ")]), _c('p', {
    staticClass: "tit-txt line-height-15"
  }, [_vm._v(" 정부기관, 관공서, 국방등 주요정보통신시설과 은행, 증권사, 캐피탈등 금융권과 민간기업의 수많은 프로젝트 수행을 통해 안전한 망분리와 망연계, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 시스템접근제어, 통합로그인을 지원하는 FIDO 기반의 생체인증솔루션을 판매, 구축하고 통합유지보수 하고 있습니다. ")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }