var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "h-100 border-top-0",
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('v-img', {
    staticClass: "image-card__image",
    attrs: {
      "src": _vm.image,
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }), _c('div', {
    staticClass: "border-top pa-20 pa-md-24"
  }, [_c('h5', {
    staticClass: "tit-txt mb-6 mb-lg-12",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]), _c('ul', {
    staticClass: "dot-texts page-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }, [_vm._t("default")], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }