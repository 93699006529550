<template>
    <client-page>
        <solution-sub-visual />
        <solution-tab />
        <sub-section tit="mSABER" titTxt="모바일 생체인증 솔루션" className="section--first">
            <sub-section small className="page-section--first">
                <v-img src="/images/sub/solution/msaber/msaber-img1.jpg" class="w-100" data-aos="fade-up" data-aos-delay="150"/>
            </sub-section>
            <sub-section small>
                <tit-logo list tit="제품 개요" logo="/images/sub/solution/msaber/top-logo.jpg">
                    <ul class="dot-texts page-text grey--text line-height-15 word-keep-all" data-aos="fade-up" data-aos-delay="150">
                        <li class="dot-text">
                            · 모바일 기기의 생체정보를 활용하여 다양한 업무시스템 로그인 시 PW 및 공인인증서를 대체하여 더욱 강력한 본인 인증서비스를 <br class="d-none d-lg-block">
                            제공하는 차세대 인증 플랫폼
                        </li>
                        <li class="dot-text">
                            · 지식기반 ID/PW → 소유기반 OTP → 생체기반 인증의 변화에 맞추어 인증 신뢰성, 보안성, 편의성 강화
                        </li>
                    </ul>
                </tit-logo>
            </sub-section>
        </sub-section>
        <sub-section sectionTit="주요기능" className="grey lighten-5 section--last">
            <v-row no-gutters class="icon-cards cols-3">
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="01" icon="/images/sub/solution/msaber/msaber-icon1-1.svg">
                        <template slot="tit">
                            간편하고 빠른 인증
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 스마트폰 화면 잠금 시에도 인증→특허기술
                            </li>
                            <li class="dot-text">
                                · 간편한 지문 인증, PIN 인증, 원터치, OTP 인증
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="02" icon="/images/sub/solution/msaber/msaber-icon1-2.svg">
                        <template slot="tit">
                            위치기반 인증 구조 적용
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 위치기반(Beacon, WiFi, GPS)의 인증 제공
                            </li>
                            <li class="dot-text">
                                · 인증 조건 만족 시 자동 인증 환경 제공
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="03" icon="/images/sub/solution/msaber/msaber-icon1-3.svg">
                        <template slot="tit">
                            국가 정보보안 기본 지침 준수
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 공공기관 납품 시 보안성 평가 완료
                            </li>
                            <li class="dot-text">
                                · 서버 ↔ 중계서버 ↔ 앱 간의 보안 규격 준수
                            </li>
                            <li class="dot-text">
                                · 표준암호화 모듈 적용 (https, AES256, SHA256)
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="04" icon="/images/sub/solution/msaber/msaber-icon1-4.svg">
                        <template slot="tit">
                            블록체인 기반 보안성 확보
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 인증횟수, 인증정보 등 인증 DATA 블록 암호화
                            </li>
                            <li class="dot-text">
                                · 블록체인 기반의 인증 내역 공유를 통한 신뢰성 보장
                            </li>
                            <li class="dot-text">
                                · 블록체인 사업화 시 인증 연계 영역 적용
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="05" icon="/images/sub/solution/msaber/msaber-icon1-5.svg">
                        <template slot="tit">
                            자체 Private Push 제공
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · ARS, SMS 인증 대비 비용 절감
                            </li>
                            <li class="dot-text">
                                · MQTT방식 전용 푸시 시스템을 통한 공지 알림 시스템 활용
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card list num="06" icon="/images/sub/solution/msaber/msaber-icon1-6.svg">
                        <template slot="tit">
                            다양한 업무 영역 확장적용
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · OS, 웹, 앱, 출입통제, 디바이스 등 다양한 환경의 인증 제공
                            </li>
                            <li class="dot-text">
                                · 내부업무, 고객서비스, 근태관리, 본인인증 서비스 등
                            </li>
                            <li class="dot-text">
                                · 다양한 비즈니스 모델 적용 및 확장 가능
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SolutionSubVisual from "@/components/client/solution/solution-sub-visual.vue";
import SolutionTab from "@/components/client/solution/solution-tab.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";

export default {
    components: {
        ClientPage,
        SolutionSubVisual,
        SolutionTab,
        SubSection,
        TitLogo,
        IconCard,
    },
    data() {
        return {
        };
    },
};
</script>
