var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('solution-sub-visual'), _c('solution-tab'), _c('sub-section', {
    attrs: {
      "tit": "mSABER",
      "titTxt": "모바일 생체인증 솔루션",
      "className": "section--first"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "className": "page-section--first"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/solution/msaber/msaber-img1.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "small": ""
    }
  }, [_c('tit-logo', {
    attrs: {
      "list": "",
      "tit": "제품 개요",
      "logo": "/images/sub/solution/msaber/top-logo.jpg"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text grey--text line-height-15 word-keep-all",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 모바일 기기의 생체정보를 활용하여 다양한 업무시스템 로그인 시 PW 및 공인인증서를 대체하여 더욱 강력한 본인 인증서비스를 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 제공하는 차세대 인증 플랫폼 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 지식기반 ID/PW → 소유기반 OTP → 생체기반 인증의 변화에 맞추어 인증 신뢰성, 보안성, 편의성 강화 ")])])])], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "주요기능",
      "className": "grey lighten-5 section--last"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/msaber/msaber-icon1-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 간편하고 빠른 인증 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 스마트폰 화면 잠금 시에도 인증→특허기술 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 간편한 지문 인증, PIN 인증, 원터치, OTP 인증 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/msaber/msaber-icon1-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 위치기반 인증 구조 적용 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 위치기반(Beacon, WiFi, GPS)의 인증 제공 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 인증 조건 만족 시 자동 인증 환경 제공 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/msaber/msaber-icon1-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 국가 정보보안 기본 지침 준수 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 공공기관 납품 시 보안성 평가 완료 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 서버 ↔ 중계서버 ↔ 앱 간의 보안 규격 준수 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 표준암호화 모듈 적용 (https, AES256, SHA256) ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "04",
      "icon": "/images/sub/solution/msaber/msaber-icon1-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 블록체인 기반 보안성 확보 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 인증횟수, 인증정보 등 인증 DATA 블록 암호화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 블록체인 기반의 인증 내역 공유를 통한 신뢰성 보장 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 블록체인 사업화 시 인증 연계 영역 적용 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "05",
      "icon": "/images/sub/solution/msaber/msaber-icon1-5.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 자체 Private Push 제공 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · ARS, SMS 인증 대비 비용 절감 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · MQTT방식 전용 푸시 시스템을 통한 공지 알림 시스템 활용 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "06",
      "icon": "/images/sub/solution/msaber/msaber-icon1-6.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 다양한 업무 영역 확장적용 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · OS, 웹, 앱, 출입통제, 디바이스 등 다양한 환경의 인증 제공 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 내부업무, 고객서비스, 근태관리, 본인인증 서비스 등 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 다양한 비즈니스 모델 적용 및 확장 가능 ")])])], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }