<template>
    <client-page>
        <sub-visual titOutlined="Our" tit="Solutions" sh="솔루션" tabActive="OT보안" bg="/images/sub/visual/sv--solution.jpg" />
        <sub-section tit="Nozomi Networks" titTxt="OT/IoT 사이버 보안 및 이상징후 탐지 솔루션" className="section--first">
            <tit-logo list tit="제품 개요" logo="/images/sub/solution/nozomi/top-logo.jpg">
                <div data-aos="fade-up" data-aos-delay="150">
                    <p class="page-text grey--text line-height-15 word-keep-all mb-4 mb-lg-8">
                        IT, OT 및 IoT 영역에서 혁신적인 인공지능 기술을 기반으로 산업제어 네트워크의 자산 현황 파악, 데이터 시각화 및 모니터링 등의 <br class="d-none d-lg-block">
                        어려운 작업을 자동화하는 솔루션입니다.
                    </p>
                    <p class="page-text grey--text line-height-15 word-keep-all">
                        주요시설, 에너지, 제조, 광업, 운송, 스마트 빌딩 및 기타 OT 현장에 사이버 보안 데이터를 통합하여 디지털 혁신을 가속화하고 OT/ICS <br class="d-none d-lg-block">
                        영역의 사이버 보안을 넘어 자산관리 및 유지보수 작업예측 등의 서비스로 경영 효율성 향상을 지원합니다.
                    </p>
                </div>
            </tit-logo>
        </sub-section>
        <sub-section sectionTitSm sectionTit="제품 구성도" className="grey lighten-5">
            <p class="page-text grey--text line-height-15 mb-20 mb-lg-40" data-aos="fade-up" data-aos-delay="150">
                Guardian은 중앙관리 모듈, Guardian, 수집기의 3 Tier로 구성되며, 보안 기능을 위한 Optional License로 구성됩니다.
            </p>
            <v-img src="/images/sub/solution/nozomi/nozomi-img1.jpg" class="d-none d-md-block w-100"  data-aos="fade-up" data-aos-delay="200"/>
            <v-img src="/images/sub/solution/nozomi/nozomi-img1-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto"  data-aos="fade-up" data-aos-delay="200"/>
        </sub-section>
        <sub-section sectionTit="특장점">
            <v-row no-gutters class="icon-cards cols-1">
                <v-col cols="12">
                    <icon-card list num="01" icon="/images/sub/solution/nozomi/nozomi-icon1-1.svg">
                        <template slot="tit">
                            자산 및 네트워크 연결정보 가시화 (Visibility)
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 자산 현황 파악 (IP, 운영체제, 호스트명, NIC카드 정보, 패치정보, 사용포트/통신노드 정보 등 제공)
                            </li>
                            <li class="dot-text">
                                · 자산간 연결성 가시화 (주요 산업제어 Protocol들에 대한 Parsing 기능 보유)
                            </li>
                            <li class="dot-text">
                                · 폐쇄망에서 인터넷 혹은 Zone간 비정상적 연결 시도, Zone간 원격접속 등 중요서비스 연결현황 실시간 파악
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12">
                    <icon-card list num="02" icon="/images/sub/solution/nozomi/nozomi-icon1-2.svg">
                        <template slot="tit">
                            공정설비 화이트리스트 기반 이상징후 탐지 (Baseline Deviation Detection)
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 인공지능을 통해 신규 노드 출현 및 공정설비간 비정상적 제어명령 및 센서수치를 인식하고 학습
                            </li>
                            <li class="dot-text">
                                · 평소 학습된 수치(Whitelist)를 벗어난 제어명령 및 공정변수 발생 시 Alert 발생
                            </li>
                            <li class="dot-text">
                                · 제어설비 비정상 작동, 악성코드 등에 의한 제어설비 로직 변형, Human Error 등 탐지
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12">
                    <icon-card list num="03" icon="/images/sub/solution/nozomi/nozomi-icon1-3.svg">
                        <template slot="tit">
                            전통적 침입탐지 (Intrusion Detection) 기법을 통한 위협 탐지
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 행위기반 Anomaly 기반 공격 탐지
                            </li>
                            <li class="dot-text">
                                · 패턴기반 알려진 악성코드 탐지
                            </li>
                            <li class="dot-text">
                                · 각종 스캐닝(포트 스캔, 네트워크 스캔) 공격, 서비스 거부 및 디도스 공격 등 탐지
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section sectionTit="기대 효과" className="section--last grey lighten-5">
            <tit-small tit="안전한 산업 제어망을 위한 보안 가시성 확보" sc="ICS/SCADA망의 자산 관리, 보안 위협 및 취약점 관리, 보안/감사, 주기적 리포트 등 지속적인 보안운영 체제를 완성할 수 있습니다." />
            <v-img src="/images/sub/solution/nozomi/nozomi-img2.jpg" class="w-100 mb-20 mb-lg-40"  data-aos="fade-up" data-aos-delay="250"/>
            <v-row no-gutters class="icon-cards cols-4">
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="01" icon="/images/sub/solution/nozomi/nozomi-icon2-1.svg">
                        <template slot="tit">
                            현황 파악
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 자산 정보 제공
                            </li>
                            <li class="dot-text">
                                · 구성 상태 확인
                            </li>
                            <li class="dot-text">
                                · 지속적인 최신화
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="02" icon="/images/sub/solution/nozomi/nozomi-icon2-2.svg">
                        <template slot="tit">
                            보안이슈 확인
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 자산별 취약점
                            </li>
                            <li class="dot-text">
                                · 네트워크상의 취약점
                            </li>
                            <li class="dot-text">
                                · 프로세스상의 취약점
                            </li>
                            <li class="dot-text">
                                · 이상행위/비정상행위
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="03" icon="/images/sub/solution/nozomi/nozomi-icon2-3.svg">
                        <template slot="tit">
                            시간 및 비용 감소
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 리포팅 자동화
                            </li>
                            <li class="dot-text">
                                · 포렌식 업무
                            </li>
                            <li class="dot-text">
                                · 감사 준비
                            </li>
                            <li class="dot-text">
                                · 인공지능의 자동학습
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="04" icon="/images/sub/solution/nozomi/nozomi-icon2-4.svg">
                        <template slot="tit">
                            통합 및 확장
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · SIEM과의 연동
                            </li>
                            <li class="dot-text">
                                · 방화벽과 연계하여 보안 대응 강화
                            </li>
                            <li class="dot-text">
                                · 써드 파티 솔루션과 통합 및 연동
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";
import TitSmall from "@/components/client/sub/section-tit-sm.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        TitLogo,
        TitSmall,
        IconCard,
    },
    data() {
        return {
        };
    },
};
</script>
