var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon-frame-wrap"
  }, [_c('div', {
    staticClass: "icon-frame"
  }, [_c('div', {
    staticClass: "icon-frame__inner"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.icon,
      "contain": ""
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }