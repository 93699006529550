var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.small ? _c('section', {
    class: 'section ' + _vm.className
  }, [_c('v-container', [_vm.tit ? _c('div', {
    staticClass: "tit-wrap",
    class: !_vm.titLeft ? 'text-center' : ''
  }, [_c('h3', {
    staticClass: "tit font-play"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.tit)) + " ")]), _vm.titTxt ? _c('p', {
    staticClass: "tit-txt mt-12 mt-lg-20"
  }, [_vm._v(" " + _vm._s(_vm.titTxt) + " ")]) : _vm._e()]) : _vm._e(), _vm.sectionTit ? _c('h4', {
    staticClass: "tit tit--sm tit--bar",
    class: _vm.sectionTitSm ? 'mb-6 mb-lg-12' : 'mb-20 mb-lg-40',
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.sectionTit)) + " ")]) : _vm._e(), _vm._t("default")], 2)], 1) : _c('section', {
    class: 'page-section ' + _vm.className
  }, [_vm.sectionTit ? _c('h4', {
    staticClass: "tit tit--sm tit--bar",
    class: _vm.sectionTitSm ? 'mb-6 mb-lg-12' : 'mb-20 mb-lg-40',
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.sectionTit)) + " ")]) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }