<template>
    <client-page>
        <sub-visual titOutlined="Company" tit="Introduction" sh="Introduction" tabActive="Client" bg="/images/sub/visual/sv--about.jpg" />

        <sub-section className="section--first section--last grey lighten-5">
            <sub-section small className="page-section--first">
                <h4 class="tit tit--sm tit--bar mb-6 mb-lg-12">
                    Public institutions / Local governments
                </h4>
                <div class="mt-12 mt-lg-24">
                    <v-row class="row-cols-lg-5">
                        <v-col v-for="(partner, idx) in government?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                            <div class="border">
                                <div class="d-flex justify-center white">
                                    <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </sub-section>
            <sub-section small>
                <h4 class="tit tit--sm tit--bar mb-6 mb-lg-12">
                    Finance
                </h4>    
                <div class="mt-12 mt-lg-24">
                    <v-row class="row-cols-lg-5">
                        <v-col v-for="(partner, idx) in finance?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                            <div class="border">
                                <div class="d-flex justify-center white">
                                    <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </sub-section>
            <sub-section small>
                <h4 class="tit tit--sm tit--bar mb-6 mb-lg-12">
                    General company / Other
                </h4>
                <div class="mt-12 mt-lg-24">
                    <v-row class="row-cols-lg-5">
                        <v-col v-for="(partner, idx) in etc?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                            <div class="border">
                                <div class="d-flex justify-center white">
                                    <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </sub-section>
        </sub-section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {
            government: [],
            finance: [],
            etc: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let government = await api.v1.banners.gets({ params: { code: "clients", name: "공공기관/지자체" } });
            let finance = await api.v1.banners.gets({ params: { code: "clients", name: "금융" } });
            let etc = await api.v1.banners.gets({ params: { code: "clients", name: "일반기업/기타" } });
            this.government = government.banner?.slides;
            this.finance = finance.banner?.slides;
            this.etc = etc.banner?.slides;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
