var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('solution-sub-visual'), _c('solution-tab'), _c('sub-section', {
    attrs: {
      "tit": "i-oneNAC",
      "titTxt": "네트워크 접근통제 솔루션",
      "className": "section--first"
    }
  }, [_c('tit-logo', {
    attrs: {
      "tit": "제품 개요",
      "logo": "/images/sub/solution/ionenac/top-logo.jpg"
    }
  }, [_vm._v(" i-oneNAC은 내부 사용자 및 단말에 대한 인증을 강화하여 비인가 단말에 대한 네트워크 접근을 차단하고 정보자산을 보호하는 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 네트워크 접근통제 솔루션입니다. ")])], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "제품 구성도",
      "className": "grey lighten-5"
    }
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100 border",
    attrs: {
      "src": "/images/sub/solution/ionenac/ionenac-img1.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto border",
    attrs: {
      "src": "/images/sub/solution/ionenac/ionenac-img1-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "특장점"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/ionenac/ionenac-icon1-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 강력한 보안 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · Agent 기반의 ACL 통제기능으로 외부위협 차단 및 랜섬웨어 감염 방지 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/ionenac/ionenac-icon1-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 유연한 구성 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 네트워크 운영 환경에 맞는 다양한 구성방식 지원(ARP, Mirror, Hybrid) ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/ionenac/ionenac-icon1-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 검증된 성능 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 대규모 사이트 구축 경험 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 대규모 사이트 장기간 안정적 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 서비스 제공 중 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "04",
      "icon": "/images/sub/solution/ionenac/ionenac-icon1-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 편리한 관리 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 쿼리 기반 관리자 맞춤 동적 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 대시보드 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 위젯 기반 대시보드 구성 지원 ")])])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "주요기능",
      "className": "grey lighten-5"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "01",
      "icon": "/images/sub/solution/ionenac/ionenac-icon2-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 단말 및 사용자 인증 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "02",
      "icon": "/images/sub/solution/ionenac/ionenac-icon2-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 접근통제 접근관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "03",
      "icon": "/images/sub/solution/ionenac/ionenac-icon2-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" IP 관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "04",
      "icon": "/images/sub/solution/ionenac/ionenac-icon2-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 보안 관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "05",
      "icon": "/images/sub/solution/ionenac/ionenac-icon2-5.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 운영관리 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "06",
      "icon": "/images/sub/solution/ionenac/ionenac-icon2-6.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 감사로그 ")])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "className": "section--last"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "도입분야",
      "className": "page-section--first"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "금융",
      "icon": "/images/sub/solution/ionenac/ionenac-icon3-1.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "공공",
      "icon": "/images/sub/solution/ionenac/ionenac-icon3-2.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "정보통신",
      "icon": "/images/sub/solution/ionenac/ionenac-icon3-3.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "기업",
      "icon": "/images/sub/solution/ionenac/ionenac-icon3-4.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "350"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "병원",
      "icon": "/images/sub/solution/ionenac/ionenac-icon3-5.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2",
      "data-aos": "fade-up",
      "data-aos-delay": "400"
    }
  }, [_c('icon-frame', {
    attrs: {
      "tit": "교육",
      "icon": "/images/sub/solution/ionenac/ionenac-icon3-6.svg"
    }
  })], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "제품 인증"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" · CC인증(EAL2), GS인증, IPv6 Ready 인증 ")])]), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "특허"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text grey--text mb-6 mb-lg-12",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" · IEEE 802.1x 기반의 네트워크 보안 시스템 및 네트워크 보안 방법 ")]), _c('p', {
    staticClass: "page-text dot-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" · 가상 802.1x 기반 네트워크 접근 제어 장치 및 네트워크 접근 제어 방법 ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }