var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Our",
      "tit": "Service",
      "sh": "Service",
      "bg": "/images/sub/visual/sv--service.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--first"
    }
  }, [_c('h3', {
    staticClass: "tit text-md-pre-wrap"
  }, [_vm._v(" Link-Net Korea puts customer value first "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" and delivers customer satisfaction ")])])]), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-right",
      "data-aos-delay": "100"
    }
  }, [_c('v-img', {
    staticClass: "w-100 min-h-sm-100",
    attrs: {
      "src": "/images/sub/service/service-img1.jpg",
      "max-height": "350"
    }
  })], 1), _c('v-col', {
    staticClass: "grey lighten-5",
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-left",
      "data-aos-delay": "100"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-50 pa-xl-80 d-flex align-center h-100"
  }, [_c('h5', {
    staticClass: "tit-txt line-height-15 font-weight-regular text-xl-pre-wrap"
  }, [_vm._v(" In order to immediately respond to changes in the public "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" and corporate management environment, Link-Net Korea "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" works with experienced experts to derive requirements "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" from the customer's perspective and provides customized "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" services by combining knowledge accumulated from "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" numerous SI projects. Improves satisfaction. ")])])])], 1), _c('sub-section', {
    attrs: {
      "sectionTitSm": "",
      "tit": "Integrated maintenance",
      "className": "section--first"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "Integrated maintenance",
      "className": "page-section--first"
    }
  }, [_c('p', {
    staticClass: "page-text grey--text line-height-15 word-keep-all mb-20 mb-lg-40",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v("We will provide optimal Total IT Service in the areas of system integration, outsourcing services, solutions and consulting, and specialized services.")]), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "Application/Infrastructure/Network Outsourcing"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n14 mt-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "Integrated maintenance and consignment operation management service"
    }
  })], 1)], 1)], 1), _c('v-img', {
    staticClass: "d-none d-md-block w-100",
    attrs: {
      "src": "/images/sub/service/en/service-img2.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto",
    attrs: {
      "src": "/images/sub/service/en/service-img2-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "Maintenance purpose"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "Optimization of communication network through periodic verification and analysis of communication network"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n14 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "Eliminate obstacles in advance through preventive maintenance"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n14 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "Realizing customer satisfaction through rapid response to new transfers and troubleshooting"
    }
  })], 1)], 1)], 1)], 1), _c('sub-section', [_c('h3', {
    staticClass: "tit text-center",
    attrs: {
      "data-aos": "fade-down",
      "data-aos-delay": "100"
    }
  }, [_vm._v("SI Business")]), _c('p', {
    staticClass: "tit-txt mt-12 mt-lg-20 text-center text-md-pre-wrap",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v("Link-net Korea improves customer satisfaction by deriving requirements from the customer's perspective and providing services that combine knowledge accumulated from many projects with experienced professionals.")])]), _c('div', {
    staticClass: "service-banner image-card",
    staticStyle: {
      "background-image": "url(/images/sub/service/service-banner1.jpg)"
    }
  }, [_c('v-layout', {
    staticClass: "h-100",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "flex-md-row-reverse",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-60px w-lg-100px",
    attrs: {
      "src": "/images/sub/service/service-icon2.svg",
      "contain": "",
      "max-width": "100",
      "data-aos": "zoom-out",
      "data-aos-delay": "400"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12 mb-lg-20"
  }, [_vm._v("Public SI Service")]), _c('p', {
    staticClass: "tit-txt text-md-pre-wrap"
  }, [_vm._v(" We achieve customer satisfaction through development, infrastructure, and security system construction "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" for administrative agencies, railroad corporations, and subway corporations that provide services to the public. ")])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "service-banner image-card",
    staticStyle: {
      "background-image": "url(/images/sub/service/service-banner2.jpg)"
    }
  }, [_c('v-layout', {
    staticClass: "h-100",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "text-md-right",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-60px w-lg-100px",
    attrs: {
      "src": "/images/sub/service/service-icon3.svg",
      "contain": "",
      "max-width": "100",
      "data-aos": "zoom-out",
      "data-aos-delay": "400"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12 mb-lg-20"
  }, [_vm._v("Financial SI service")]), _c('p', {
    staticClass: "tit-txt text-md-pre-wrap"
  }, [_vm._v(" Financial services are rapidly transitioning to a financial environment that incorporates non-face-to-face, "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" digital finance and new technologies following the Corona pandemic. We seek to complete safe financial business "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" by establishing the infrastructure and security systems required by financial institutions and related organizations. ")])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }