var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sub-visual', {
    attrs: {
      "titOutlined": "Our",
      "tit": "Solutions",
      "sh": "솔루션",
      "tabActive": "보안솔루션",
      "bg": "/images/sub/visual/sv--solution.jpg"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }