<template>
    <div class="form-wrap" data-aos="fade-up" data-aos-delay="150">
        <p class="text-right font-size-12 font-size-lg-14 line-height-1 grey--text mb-12 mb-lg-20"><span style="color: #FF0000">*</span> 필수입력 항목입니다.</p>
        <div class="table-style table-style--default">
            <div class="table-style__row">
                <div class="table-style__th">회사명<span style="color: #FF0000">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="form.companyName" v-bind="$attrs" outlined hide-details clearable placeholder="회사명을 입력하세요."/>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">이름<span style="color: #FF0000">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="form.name" v-bind="$attrs" outlined hide-details clearable placeholder="이름을 입력하세요."/>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">연락처 <span style="color: #FF0000">*</span></div>
                <div class="table-style__td">
                    <v-row align="center" class="row--x-small">
                        <v-col cols="4" md="auto">
                            <v-select v-model="phone1" :items="prependPhones" v-bind="$attrs" placeholder="연락처" outlined hide-details @input="() => phoneNumberJoin()" class="w-md-240px"/>
                        </v-col>
                        <v-col cols="8" md="">
                            <v-text-field v-model="phone2" v-bind="$attrs" outlined hide-details @input="() => phoneNumberJoin()" placeholder="‘-’를 제외하고 입력하세요." />
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">이메일 <span style="color: #FF0000">*</span></div>
                <div class="table-style__td">
                    <v-row align="center" class="row--x-small">
                        <v-col cols="6" md="4">
                            <v-text-field v-model="email1" v-bind="$attrs" outlined hide-details @input="() => emailJoin()" placeholder="이메일을 입력하세요." />
                        </v-col>
                        <v-col cols="6" md="4">
                            <v-text-field prefix="@" v-model="email2" v-bind="$attrs" outlined hide-details @input="() => emailJoin()"/>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select v-model="email3" v-bind="$attrs" persistent-hint :items="prependEmails" item-text="text" item-value="value" outlined hide-details @input="email2 = email3"/>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">솔루션 <span style="color: #FF0000">*</span></div>
                <div class="table-style__td">
                    <v-row align="center" class="row--x-small">
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="i-oneNet" :label="`i-oneNet`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="i-oneNac" :label="`i-oneNac`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="Safe IP" :label="`Safe IP`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="NGS" :label="`NGS`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="i-oneJTac" :label="`i-oneJTac`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="mSABER" :label="`mSABER`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="인프라-서버 및 주변기기" :label="`인프라-서버 및 주변기기`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="인프라-네트워크" :label="`인프라-네트워크`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="인프라-상용소프트웨어" :label="`인프라-상용소프트웨어`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="OT보안-nozomi" :label="`OT보안-nozomi`" class="v-checkbox--primary" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox hide-details v-model="form.solution" value="클라우드-오케스트로" :label="`클라우드-오케스트로`" class="v-checkbox--primary" />
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">문의내용 <span style="color: #FF0000">*</span></div>
                <div class="table-style__td">
                    <v-textarea rows="8" v-model="form.content" placeholder="문의내용을 입력하세요" outlined persistent-placeholder hide-details />
                </div>
            </div>
        </div>

        <div class="form-agree-box">
            <v-checkbox v-model="agree" label="개인정보 수집·이용 동의" value="개인정보 수집·이용 동의" hide-details />
            <v-btn onclick="window.open('/privacy', '', 'width=483,height=700');" color="grey lighten-1" text height="auto" class="ml-12 ml-lg-24 v-btn--underline">전문보기</v-btn>
        </div>
        <v-layout justify-center class="mt-40 mt-lg-60">
            <v-btn color="primary" @click="submit" class="min-w-180px min-w-lg-240px">문의하기</v-btn>
        </v-layout>

    </div>
</template>
<script>

import api from "@/api";

export default {
    components: {
    },
    data(){
        return {

            form:{
                code: "inquiry",
                name: "",
                phone: "",
                email: "",
                companyName: "",
                content: "",
                solution: [],
            },

            phone1: "010",
            phone2: "",
            phone3: "",

            email1: "",
            email2: "",
            email3: "",

            agree: false,

            prependPhones: [
                { text: "010", value: "010" },
                { text: "011", value: "011" },
                { text: "016", value: "016" },
                { text: "017", value: "017" },
                { text: "019", value: "019" }
            ],
            prependEmails: [
                { text: ":: 직접입력 ::", value: "" },
                { text: "naver.com", value: "naver.com" },
                { text: "gmail.com", value: "gmail.com" },
                { text: "daum.net", value: "daum.net" },
                { text: "hanmail.net", value: "hanmail.net" },
            ],
            settings: "",

            filter: {
                code: "ko"
            }
        }
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            const { settings } = await api.v1.boards.settings.get({
                headers: this.filter
            })
            this.settings = settings[0]
        },
        validate(){
            try{
                let form = this.form
                if(!form.companyName) throw new Error('회사명을 입력해주세요')
                if(!form.name) throw new Error('이름을 입력해주세요')
                if(!form.email || !this.email1 || !this.email2) throw new Error('이메일을 입력해주세요')
                if(!form.phone || !this.phone1 || !this.phone2) throw new Error('연락처를 입력해주세요')
                if(form.solution?.length == 0) throw new Error('문의사항을 체크해주세요')
                if(!this.agree) throw new Error('개인정보 수집·이용에 동의해주세요')
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async submit(){
            if(this.validate()){
                let form = this.form
                let { success } = await api.v1.forms.post({ form })
                if(success){
                    alert('문의 신청이 완료됐습니다.')
                    this.$router.push('/')
                }
            }
        },
        phoneNumberJoin(){
            this.form.phone = `${this.phone1}-${this.phone2}`
        },
        emailJoin(){
            this.form.email = `${this.email1}@${this.email2}`
        },
    },
    watch:{
        email3(){
            this.emailJoin()
        }
    }
}
</script>

<style lang="scss" scoped>
.v-btn--underline.v-size--default{
    padding: 0 0 4px 0;
    min-width: 0;
    border-bottom: 1px solid var(--v-grey-base);
    font-size: 1.4rem;
}
.form-agree-box{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--v-grey-lighten4);
    padding: 20px;
    margin-top: 20px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .form-agree-box{
        padding: 30px;
    }
}
@media (min-width:1024px){
    .form-agree-box{
        border-width: 4px;
        margin-top: 40px;
    }
}
@media (min-width:1200px){
}

</style>