var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": "이메일무단수집거부",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--first section--last"
    }
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 우리 \"링크넷코리아\" 회원에게 무차별적으로 보내지는 타사의 메일을 차단하기 위해, 본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시 "), _c('strong', [_vm._v("정보통신망법에 의해 형사처벌됨")]), _vm._v("을 유념하시기 바랍니다. 게시일 2017년 5월 11일 ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }