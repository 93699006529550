var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "flex-column-reverse flex-md-row"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm tit--bar mb-6 mb-lg-12",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]), _c('p', {
    staticClass: "page-text grey--text line-height-15 word-keep-all",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [!_vm.list ? _vm._t("default") : _vm._e()], 2), _vm.list ? _vm._t("default") : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-img', {
    staticClass: "w-100 mw-200px mw-md-280px mx-auto border",
    attrs: {
      "src": _vm.logo,
      "contain": "",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }