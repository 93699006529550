var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Our",
      "tit": "Service",
      "sh": "서비스",
      "bg": "/images/sub/visual/sv--service.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "className": "section--first"
    }
  }, [_c('h3', {
    staticClass: "tit text-md-pre-wrap"
  }, [_vm._v(" " + _vm._s(_vm.$t("service.title")) + " "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t("service.titlePrimary")) + " ")])])]), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-right",
      "data-aos-delay": "100"
    }
  }, [_c('v-img', {
    staticClass: "w-100 min-h-sm-100",
    attrs: {
      "src": "/images/sub/service/service-img1.jpg",
      "max-height": "350"
    }
  })], 1), _c('v-col', {
    staticClass: "grey lighten-5",
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-left",
      "data-aos-delay": "100"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-50 pa-xl-80 d-flex align-center h-100"
  }, [_c('h5', {
    staticClass: "tit-txt line-height-15 font-weight-regular text-xl-pre-wrap"
  }, [_vm._v(_vm._s(_vm.$t("service.text")))])])])], 1), _c('sub-section', {
    attrs: {
      "sectionTitSm": "",
      "tit": "serviceMaintenance.title",
      "className": "section--first"
    }
  }, [_c('sub-section', {
    attrs: {
      "small": "",
      "sectionTitSm": "",
      "sectionTit": "serviceMaintenance.title",
      "className": "page-section--first"
    }
  }, [_c('p', {
    staticClass: "page-text grey--text line-height-15 word-keep-all mb-20 mb-lg-40",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(_vm._s(_vm.$t("serviceMaintenance.text")))]), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "serviceMaintenance.list1"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n14 mt-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "serviceMaintenance.list2"
    }
  })], 1)], 1)], 1), _c('v-img', {
    staticClass: "d-none d-md-block w-100",
    attrs: {
      "src": _vm.$t('serviceMaintenance.image'),
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto",
    attrs: {
      "src": _vm.$t('serviceMaintenance.imageMobile'),
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "small": "",
      "sectionTit": "servicePurpose.title"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "servicePurpose.list1"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n14 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "servicePurpose.list2"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n14 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('text-card', {
    attrs: {
      "tit": "servicePurpose.list3"
    }
  })], 1)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "className": "section--first grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit",
    attrs: {
      "data-aos": "fade-down",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" 컨설팅 서비스 ")]), _c('p', {
    staticClass: "tit-txt mt-12 mt-lg-20",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" Link-net Korea는 경험이 풍부한 컨설턴트를 중심으로 고객사의 업무특성 및 현황을 정확히 이해하고 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 최적화된 정보보호관리체계를 구축/운영하는 것으로써. 체계적인 컨설팅을 수행합니다. ")])]), _c('v-row', {
    staticClass: "icon-cards cols-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "01",
      "icon": "/images/sub/service/service-icon1-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 기술적 취약점 진단 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 컨설팅 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "02",
      "icon": "/images/sub/service/service-icon1-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" ISO27001 / KISA ISMS "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 인증 컨설팅 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "03",
      "icon": "/images/sub/service/service-icon1-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 정보보호체계 구축 취약점 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 진단 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "04",
      "icon": "/images/sub/service/service-icon1-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 보안감사 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "05",
      "icon": "/images/sub/service/service-icon1-5.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 내/외부 모의해킹 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "06",
      "icon": "/images/sub/service/service-icon1-6.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 어플리케이션 진단 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "07",
      "icon": "/images/sub/service/service-icon1-7.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 개인정보보호 / ISMS-P "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 인증 컨설팅 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "08",
      "icon": "/images/sub/service/service-icon1-8.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 내부정보 유출방지 컨설팅 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 서비스 ")])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('icon-card', {
    attrs: {
      "num": "09",
      "icon": "/images/sub/service/service-icon1-9.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 장기보안(ON going) "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 서비스 ")])], 2)], 1)], 1)], 1), _c('sub-section', {
    staticClass: "section--first"
  }, [_c('h3', {
    staticClass: "tit text-center",
    attrs: {
      "data-aos": "fade-down",
      "data-aos-delay": "100"
    }
  }, [_vm._v(_vm._s(_vm.$t("serviceSI.title")))]), _c('p', {
    staticClass: "tit-txt mt-12 mt-lg-20 text-center text-md-pre-wrap",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(_vm._s(_vm.$t("serviceSI.text")))])]), _c('div', {
    staticClass: "service-banner image-card",
    staticStyle: {
      "background-image": "url(/images/sub/service/service-banner1.jpg)"
    }
  }, [_c('v-layout', {
    staticClass: "h-100",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "flex-md-row-reverse",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-60px w-lg-100px",
    attrs: {
      "src": "/images/sub/service/service-icon2.svg",
      "contain": "",
      "max-width": "100",
      "data-aos": "zoom-out",
      "data-aos-delay": "400"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12 mb-lg-20"
  }, [_vm._v(_vm._s(_vm.$t("serviceSI.public")))]), _c('p', {
    staticClass: "tit-txt text-md-pre-wrap"
  }, [_vm._v(_vm._s(_vm.$t("serviceSI.publicText")))])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "service-banner image-card",
    staticStyle: {
      "background-image": "url(/images/sub/service/service-banner2.jpg)"
    }
  }, [_c('v-layout', {
    staticClass: "h-100",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "text-md-right",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-60px w-lg-100px",
    attrs: {
      "src": "/images/sub/service/service-icon3.svg",
      "contain": "",
      "max-width": "100",
      "data-aos": "zoom-out",
      "data-aos-delay": "400"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12 mb-lg-20"
  }, [_vm._v(_vm._s(_vm.$t("serviceSI.finance")))]), _c('p', {
    staticClass: "tit-txt text-md-pre-wrap"
  }, [_vm._v(_vm._s(_vm.$t("serviceSI.financeText")))])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }