var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border border-lg-2 primary h-100 text-center"
  }, [_c('v-layout', {
    staticClass: "pa-12 text-center white h-100",
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('p', {
    staticClass: "page-text primary--text font-weight-bold word-keep-all"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.tit)) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }