<template>
    <section v-if="!small" :class="'section ' + className">
        <v-container>
            <div v-if="tit" class="tit-wrap" :class="!titLeft ? 'text-center' : ''">
                <h3 class="tit font-play">
                    {{ $t(tit) }}
                </h3>
                <p v-if="titTxt" class="tit-txt mt-12 mt-lg-20">
                    {{titTxt}}
                </p>
            </div>
            <h4 v-if="sectionTit" class="tit tit--sm tit--bar" :class="sectionTitSm ? 'mb-6 mb-lg-12' : 'mb-20 mb-lg-40'" data-aos="fade-up" data-aos-delay="100">
                {{ $t(sectionTit) }}
            </h4>
            <slot />
        </v-container>
    </section>
    <section v-else :class="'page-section ' + className">
        <h4 v-if="sectionTit" class="tit tit--sm tit--bar" :class="sectionTitSm ? 'mb-6 mb-lg-12' : 'mb-20 mb-lg-40'" data-aos="fade-up" data-aos-delay="100">
            {{ $t(sectionTit) }}
        </h4>
        <slot />
    </section>
</template>

<script>

export default {
    props: {
        className: { type: String, default: "" },
        tit: { type: String, default: "" },
        titTxt: { type: String, default: "" },
        sectionTit: { type: String, default: "" },
        titLeft: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
        sectionTitSm: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
.section{
    padding: 40px 0;
    &--first{
        padding-top: 80px;
    }
    &--last{
        padding-bottom: 80px;
    }
}
.page-section{
    padding-top: 40px;
    &--first{
        padding-top: 0 !important;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .section{
        padding: 60px 0;
        &--first{
            padding-top: 120px;
        }
        &--last{
            padding-bottom: 120px;
        }
    }
    .page-section{
        padding-top: 60px;
    }
}
@media (min-width:1024px){
    .section{
        padding: 80px 0;
        &--first{
            padding-top: 160px;
        }
        &--last{
            padding-bottom: 160px;
        }
    }
    .page-section{
        padding-top: 80px;
    }
}
@media (min-width:1200px){
}
</style>
