var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-head"
  }, [_c('div', {
    staticClass: "sub-visual",
    attrs: {
      "data-aos": "fade",
      "data-aos-delay": "100"
    }
  }, [_c('div', {
    staticClass: "sub-visual__bg",
    style: 'background-image:url(' + _vm.bg + ');'
  }), _c('v-container', [_c('h2', {
    staticClass: "sub-visual__tit font-play",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "300"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.titOutlined))]), _vm._v(" " + _vm._s(_vm.tit))])])], 1), _vm.tabActive ? _c('client-gnb', {
    attrs: {
      "className": "sub-tab-wrap",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }