<template>
    <client-page>
        <solution-sub-visual />
        <solution-tab />
        <sub-section tit="i-oneJTac" titTxt="원격접속 재택근무 보안솔루션" className="section--first">
            <sub-section small className="page-section--first">
                <tit-logo tit="제품 개요" logo="/images/sub/solution/ionejtac/top-logo.jpg">
                    아이원재택(i-oneJTac)은 재택근무 보안 고려사항을 준수하며, 안전한 재택근무를 위한 편리한 원격접속과 강화된 보안까지 한번에 <br class="d-none d-lg-block">
                    제공하는 재택근무 솔루션입니다.
                </tit-logo>
            </sub-section>
            <sub-section small sectionTit="도입 필요성">
                <v-row class="row--large">
                    <v-col cols="12" sm="4">
                        <image-card image="/images/sub/solution/ionejtac/ionejtac-img1-1.jpg" tit="보안 컴플라이언스 준수">
                            <li class="dot-text">
                                · 금융감독원 전자금융감독규정시행세칙 개정
                            </li>
                            <li class="dot-text">
                                · 금융보안원 재택근무 보안 안내서
                            </li>
                            <li class="dot-text">
                                · 방산 및 협력업체 비대면·재택근무 보안 안내서
                            </li>
                        </image-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <image-card image="/images/sub/solution/ionejtac/ionejtac-img1-2.jpg" tit="원격접속 관리">
                            <li class="dot-text">
                                · 서버와 단말기 클라이언트 간 상호인증
                            </li>
                            <li class="dot-text">
                                · 사용자별, 부서별 보안정책 설정
                            </li>
                            <li class="dot-text">
                                · 원격접속 현황 모니터링
                            </li>
                        </image-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <image-card image="/images/sub/solution/ionejtac/ionejtac-img1-3.jpg" tit="보안환경 마련">
                            <li class="dot-text">
                                · 재택PC에 내부업무 환경 수준의 보안 적용
                            </li>
                            <li class="dot-text">
                                · 재택PC에 백신 설치 및 최신 업데이트 관리
                            </li>
                        </image-card>
                    </v-col>
                </v-row>
            </sub-section>
        </sub-section>
        <sub-section className="grey lighten-5">
            <sub-section sectionTit="제품 구성도" small className="page-section--first">
                <v-img src="/images/sub/solution/ionejtac/ionejtac-img2.jpg" class="d-none d-md-block w-100 border"  data-aos="fade-up" data-aos-delay="150"/>
                <v-img src="/images/sub/solution/ionejtac/ionejtac-img2-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border"  data-aos="fade-up" data-aos-delay="150"/>
            </sub-section>
            <sub-section small sectionTit="프로세스">
                <v-row justify="center" class="row--large row-cols-md-5">
                    <v-col cols="6" sm="4">
                        <icon-frame-step step="01" width="20" icon="/images/sub/solution/ionejtac/ionejtac-icon1-1.svg">
                            재택근무 신청 및 승인 <br class="d-none d-lg-block">
                            (기간 및 용도)
                        </icon-frame-step>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <icon-frame-step step="02" width="40" icon="/images/sub/solution/ionejtac/ionejtac-icon1-2.svg">
                            클라이언트 로그인 <br class="d-none d-lg-block">
                            (2-Factor 인증)
                        </icon-frame-step>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <icon-frame-step step="03" width="60" icon="/images/sub/solution/ionejtac/ionejtac-icon1-3.svg">
                            보안서약서 <br class="d-none d-lg-block">
                            동의
                        </icon-frame-step>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <icon-frame-step step="04" width="80" icon="/images/sub/solution/ionejtac/ionejtac-icon1-4.svg">
                            재택근무 수행 <br class="d-none d-lg-block">
                            (사내 업무망 PC 원격접속)
                        </icon-frame-step>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <icon-frame-step step="05" width="100" icon="/images/sub/solution/ionejtac/ionejtac-icon1-5.svg">
                            재택근무 종료 및 <br class="d-none d-lg-block">
                            로그아웃
                        </icon-frame-step>
                    </v-col>
                </v-row>
            </sub-section>
        </sub-section>
        <sub-section sectionTit="특장점">
            <v-row no-gutters class="icon-cards cols-4">
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="01" icon="/images/sub/solution/ionejtac/ionejtac-icon2-1.svg">
                        <template slot="tit">
                            편리한 원격접속 <br class="d-none d-lg-block">
                            및 안전한 보안
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 하나의 솔루션으로 원격접속과 안전한 보안 환경 제공
                            </li>
                            <li class="dot-text">
                                · 재택단말기 보안 환경 점검 및 설정가이드
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="02" icon="/images/sub/solution/ionejtac/ionejtac-icon2-2.svg">
                        <template slot="tit">
                            보안규정에 <br class="d-none d-lg-block">
                            최적화된 솔루션
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 재택근무 시 필수 의무사항 적용 및 권고사항 핵심 요소 반영
                            </li>
                            <li class="dot-text">
                                · 주요 보안 위협 요소 통제
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="03" icon="/images/sub/solution/ionejtac/ionejtac-icon2-3.svg">
                        <template slot="tit">
                            강력한 보안 <br class="d-none d-lg-block">
                            환경
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 불필요한 외부 접속 차단 (MAC 주소 자동 등록)
                            </li>
                            <li class="dot-text">
                                · 2-Factor 인증 지원
                            </li>
                            <li class="dot-text">
                                · 외부 단말기 보안 관리 및 내부정보 유출 방지
                            </li>
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <icon-card list num="04" icon="/images/sub/solution/ionejtac/ionejtac-icon2-4.svg">
                        <template slot="tit">
                            사용 편의성 <br class="d-none d-lg-block">
                            및 효율성
                        </template>
                        <template slot="list">
                            <li class="dot-text">
                                · 간편한 원격접속, 빠른 반응속도
                            </li>
                            <li class="dot-text">
                                · 멀티 모니터 및 사운드 지원
                            </li>
                            <li class="dot-text">
                                · 재택근무자 모니터링 및 관리
                            </li>
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section sectionTit="주요기능" className="grey lighten-5">
            <v-row no-gutters class="icon-cards cols-3">
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="01" icon="/images/sub/solution/ionejtac/ionejtac-icon3-1.svg">
                        <template slot="tit">
                            외부 단말기 보안관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="02" icon="/images/sub/solution/ionejtac/ionejtac-icon3-2.svg">
                        <template slot="tit">
                            내부정보 유출방지
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="03" icon="/images/sub/solution/ionejtac/ionejtac-icon3-3.svg">
                        <template slot="tit">
                            내부망 접근통제
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="04" icon="/images/sub/solution/ionejtac/ionejtac-icon3-4.svg">
                        <template slot="tit">
                            사용자 보안인증
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="05" icon="/images/sub/solution/ionejtac/ionejtac-icon3-5.svg">
                        <template slot="tit">
                            통신구간 보안관리
                        </template>
                    </icon-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <icon-card num="06" icon="/images/sub/solution/ionejtac/ionejtac-icon3-6.svg">
                        <template slot="tit">
                            보안정책 설정
                        </template>
                    </icon-card>
                </v-col>
            </v-row>
        </sub-section>
        <sub-section sectionTitSm sectionTit="제품 인증" className="section--last">
            <p class="page-text dot-text grey--text" data-aos="fade-up" data-aos-delay="150">
                · GS 인증, 클라우드 서비스 확인제 인증
            </p>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SolutionSubVisual from "@/components/client/solution/solution-sub-visual.vue";
import SolutionTab from "@/components/client/solution/solution-tab.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";
import IconCard from "@/components/client/sub/icon-card-primary.vue";
import IconFrameStep from "@/components/client/sub/icon-frame-step.vue";
import ImageCard from "@/components/client/sub/image-card.vue";

export default {
    components: {
        ClientPage,
        SolutionSubVisual,
        SolutionTab,
        SubSection,
        TitLogo,
        IconCard,
        IconFrameStep,
        ImageCard,
    },
    data() {
        return {
        };
    },
};
</script>
