<template>
    <v-card color="transparent" rounded="0" elevation="0">
        <div class="pa-20 pa-md-30 pa-lg-40">
            <v-row class="row--small">
                <v-col data-aos="fade-up" data-aos-delay="100">
                    <p class="font-play page-text font-weight-bold primary--text line-height-1 mb-4 mb-lg-8">
                        {{num}}
                    </p>
                    <h5 class="tit-txt word-keep-all">
                        <slot name="tit" />
                    </h5>
                </v-col>
                <v-col cols="auto" data-aos="zoom-out" data-aos-duration="800" data-aos-delay="300">
                    <v-img :src="icon" contain class="icon-card__icon"/>
                </v-col>
            </v-row>
            <ul v-if="list" class="mt-12 mt-lg-24 page-text dot-texts word-keep-all" data-aos="fade-up" data-aos-delay="200">
                <slot name="list" />
            </ul>
        </div>
    </v-card>
</template>

<script>

export default {
    props: {
        num: { type: String, default: "" },
        icon: { type: String, default: "" },
        list: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
.icon-card__icon{
    max-width: 40px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-card__icon{
        max-width: 48px;
    }
}
@media (min-width:1200px){
}

</style>
