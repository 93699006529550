var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "titOutlined": "Our",
      "tit": "Solutions",
      "sh": "솔루션",
      "tabActive": "OT보안",
      "bg": "/images/sub/visual/sv--solution.jpg"
    }
  }), _c('sub-section', {
    attrs: {
      "tit": "Nozomi Networks",
      "titTxt": "OT/IoT 사이버 보안 및 이상징후 탐지 솔루션",
      "className": "section--first"
    }
  }, [_c('tit-logo', {
    attrs: {
      "list": "",
      "tit": "제품 개요",
      "logo": "/images/sub/solution/nozomi/top-logo.jpg"
    }
  }, [_c('div', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('p', {
    staticClass: "page-text grey--text line-height-15 word-keep-all mb-4 mb-lg-8"
  }, [_vm._v(" IT, OT 및 IoT 영역에서 혁신적인 인공지능 기술을 기반으로 산업제어 네트워크의 자산 현황 파악, 데이터 시각화 및 모니터링 등의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 어려운 작업을 자동화하는 솔루션입니다. ")]), _c('p', {
    staticClass: "page-text grey--text line-height-15 word-keep-all"
  }, [_vm._v(" 주요시설, 에너지, 제조, 광업, 운송, 스마트 빌딩 및 기타 OT 현장에 사이버 보안 데이터를 통합하여 디지털 혁신을 가속화하고 OT/ICS "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 영역의 사이버 보안을 넘어 자산관리 및 유지보수 작업예측 등의 서비스로 경영 효율성 향상을 지원합니다. ")])])])], 1), _c('sub-section', {
    attrs: {
      "sectionTitSm": "",
      "sectionTit": "제품 구성도",
      "className": "grey lighten-5"
    }
  }, [_c('p', {
    staticClass: "page-text grey--text line-height-15 mb-20 mb-lg-40",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" Guardian은 중앙관리 모듈, Guardian, 수집기의 3 Tier로 구성되며, 보안 기능을 위한 Optional License로 구성됩니다. ")]), _c('v-img', {
    staticClass: "d-none d-md-block w-100",
    attrs: {
      "src": "/images/sub/solution/nozomi/nozomi-img1.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto",
    attrs: {
      "src": "/images/sub/solution/nozomi/nozomi-img1-mo.jpg",
      "max-width": "702",
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  })], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "특장점"
    }
  }, [_c('v-row', {
    staticClass: "icon-cards cols-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/nozomi/nozomi-icon1-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 자산 및 네트워크 연결정보 가시화 (Visibility) ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 자산 현황 파악 (IP, 운영체제, 호스트명, NIC카드 정보, 패치정보, 사용포트/통신노드 정보 등 제공) ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 자산간 연결성 가시화 (주요 산업제어 Protocol들에 대한 Parsing 기능 보유) ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 폐쇄망에서 인터넷 혹은 Zone간 비정상적 연결 시도, Zone간 원격접속 등 중요서비스 연결현황 실시간 파악 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/nozomi/nozomi-icon1-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 공정설비 화이트리스트 기반 이상징후 탐지 (Baseline Deviation Detection) ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 인공지능을 통해 신규 노드 출현 및 공정설비간 비정상적 제어명령 및 센서수치를 인식하고 학습 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 평소 학습된 수치(Whitelist)를 벗어난 제어명령 및 공정변수 발생 시 Alert 발생 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 제어설비 비정상 작동, 악성코드 등에 의한 제어설비 로직 변형, Human Error 등 탐지 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/nozomi/nozomi-icon1-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 전통적 침입탐지 (Intrusion Detection) 기법을 통한 위협 탐지 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 행위기반 Anomaly 기반 공격 탐지 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 패턴기반 알려진 악성코드 탐지 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 각종 스캐닝(포트 스캔, 네트워크 스캔) 공격, 서비스 거부 및 디도스 공격 등 탐지 ")])])], 2)], 1)], 1)], 1), _c('sub-section', {
    attrs: {
      "sectionTit": "기대 효과",
      "className": "section--last grey lighten-5"
    }
  }, [_c('tit-small', {
    attrs: {
      "tit": "안전한 산업 제어망을 위한 보안 가시성 확보",
      "sc": "ICS/SCADA망의 자산 관리, 보안 위협 및 취약점 관리, 보안/감사, 주기적 리포트 등 지속적인 보안운영 체제를 완성할 수 있습니다."
    }
  }), _c('v-img', {
    staticClass: "w-100 mb-20 mb-lg-40",
    attrs: {
      "src": "/images/sub/solution/nozomi/nozomi-img2.jpg",
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }), _c('v-row', {
    staticClass: "icon-cards cols-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "01",
      "icon": "/images/sub/solution/nozomi/nozomi-icon2-1.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 현황 파악 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 자산 정보 제공 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 구성 상태 확인 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 지속적인 최신화 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "02",
      "icon": "/images/sub/solution/nozomi/nozomi-icon2-2.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 보안이슈 확인 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 자산별 취약점 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 네트워크상의 취약점 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 프로세스상의 취약점 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 이상행위/비정상행위 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "03",
      "icon": "/images/sub/solution/nozomi/nozomi-icon2-3.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 시간 및 비용 감소 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 리포팅 자동화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 포렌식 업무 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 감사 준비 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 인공지능의 자동학습 ")])])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('icon-card', {
    attrs: {
      "list": "",
      "num": "04",
      "icon": "/images/sub/solution/nozomi/nozomi-icon2-4.svg"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 통합 및 확장 ")]), _c('template', {
    slot: "list"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · SIEM과의 연동 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 방화벽과 연계하여 보안 대응 강화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" · 써드 파티 솔루션과 통합 및 연동 ")])])], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }