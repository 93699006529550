<template>
    <div class="border border-lg-2 primary h-100 text-center">
        <v-layout justify-center align-center class="pa-12 text-center white h-100">
            <p class="page-text primary--text font-weight-bold word-keep-all">
                {{ $t(tit) }}
            </p>
        </v-layout>
    </div>
</template>

<script>

export default {
    props: {
        tit: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
</style>
