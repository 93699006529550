<template>
    <client-page>
        <sub-visual tit="이메일무단수집거부" bg="/images/sub/visual/sv--about.jpg" />
        <sub-section className="section--first section--last">
            <p class="page-text">
                우리 "링크넷코리아" 회원에게 무차별적으로 보내지는 타사의 메일을 차단하기 위해, 본 웹사이트에 게시된 이메일 주소가
                전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시
                <strong>정보통신망법에 의해 형사처벌됨</strong>을 유념하시기 바랍니다.

                게시일 2017년 5월 11일
            </p>
        </sub-section>     
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
