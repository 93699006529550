<template>
    <client-page>
        <sub-visual titOutlined="Our" tit="Solutions" sh="솔루션" tabActive="클라우드" bg="/images/sub/visual/sv--solution.jpg" />
        <sub-section tit="오케스트로" titTxt="클라우드 관리 플랫폼" className="section--first section--last">
            <sub-section small className="page-section--first">
                <tit-logo tit="제품 개요" logo="/images/sub/solution/cloud/top-logo.jpg">
                    오케스트로에서 제공하고 있는 최적의 솔루션 Full Stack을 통해 클라우드 환경의 인프라에서 운영관리까지의 전 범위를 지원합니다.
                </tit-logo>
            </sub-section>
            <sub-section small sectionTit="제품 구성도">
                <v-img src="/images/sub/solution/cloud/cloud-img1.jpg" class="d-none d-md-block w-100 border"  data-aos="fade-up" data-aos-delay="150"/>
                <v-img src="/images/sub/solution/cloud/cloud-img1-mo.jpg" max-width="702" class="d-block d-md-none w-100 mx-auto border"  data-aos="fade-up" data-aos-delay="150"/>
            </sub-section>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import TitLogo from "@/components/client/sub/section-tit-logo.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        TitLogo,
    },
    data() {
        return {
        };
    },
};
</script>
