var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-top"
  }, [_c('v-container', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('ul', {
    staticClass: "footer-link"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/en/about/clients"
    }
  }, [_vm._v(" Introduction ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/en/about/location"
    }
  }, [_vm._v(" Location ")])], 1)]), _c('ul', {
    staticClass: "footer-sns"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "https://blog.naver.com/NBlogTop.naver?isHttpsRedirect=true&blogId=linknkor",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/blog.png",
      "alt": "blog"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.facebook.com/linknkor",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/facebook.png",
      "alt": "facebook"
    }
  })])])])])], 1), _c('div', {
    staticClass: "footer-bottom"
  }, [_c('v-container', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('div', {}, [_c('h3', {
    staticClass: "font-play font-weight-bold primary--text mb-16"
  }, [_vm._v("Link-Net Korea")]), _c('ul', {
    staticClass: "footer-info mb-16"
  }, [_vm._l(_vm.info, function (item) {
    return _c('li', {
      key: item._id
    }, [_c('span', [_vm._v(_vm._s(item.term))]), _vm._v(" " + _vm._s(item.desc) + " ")]);
  }), _c('li', [_c('span', [_vm._v("CEO")]), _vm._v("Hong Daejin")]), _c('li', [_c('span', [_vm._v("Business Registration Number")]), _vm._v("891-86-00507")]), _c('li', [_c('span', [_vm._v("Tel")]), _vm._v("02-780-2939")]), _c('li', [_c('span', [_vm._v("Fax")]), _vm._v("02-785-2939")]), _c('li', [_c('span', [_vm._v("Email")]), _vm._v("sales@linknkor.co.kr")]), _c('li', [_c('span', [_vm._v("Address")]), _vm._v("#703, 704, 532, Yangcheon-ro, Gangseo-gu, Seoul, Republic of Korea")])], 2), _c('p', {
    staticClass: "copyright"
  }, [_vm._v("Copyright 2023. Link-Net Korea. All Rights Reserved.")])]), _c('div', [_c('u-scroll-top', {
    staticClass: "mt-md-auto ml-md-auto"
  })], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }