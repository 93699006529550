var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('icon-frame', {
    staticClass: "mb-12 mb-lg-24",
    attrs: {
      "icon": _vm.icon,
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }), _c('v-progress-linear', {
    staticClass: "mb-12 mb-lg-24",
    attrs: {
      "height": "2",
      "value": _vm.width,
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }), _c('p', {
    staticClass: "font-play page-text font-weight-bold primary--text line-height-1 mb-4 mb-lg-8",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }, [_vm._v(" step " + _vm._s(_vm.step) + " ")]), _c('p', {
    staticClass: "page-text word-keep-all",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }