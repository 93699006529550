<template>
    <client-page>
        <sub-visual titOutlined="Company" tit="Introduction" sh="Introduction" tabActive="History" bg="/images/sub/visual/sv--about.jpg" />
        <sub-section className="section--location section--first section--last">
            <v-img src="/images/sub/about/history/history-img.jpg" class="w-100 mb-40 mb-md-0" />
            <div class="history--group">
                <v-row no-gutters v-for="(history, idx) in historyList" :key="idx" class="history">
                    <v-col cols="12" md="6">
                        <div class="history__inner px-md-20 px-lg-40">
                            <h3 class="tit line-height-1 font-play mb-20 mb-lg-40" data-aos="fade-up" data-aos-delay="150">
                                {{ history.subject }}
                            </h3>
                            <ul class="dot-texts" data-aos="fade-up" data-aos-delay="200">
                                <li v-for="(content, idx) in history.contents" :key="idx" class="dot-text page-text grey--text">· {{ content.detail }}</li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <!-- <v-container>
                <div v-for="list, idx in history" :key="idx">
                    <v-row>
                        {{list.subject}}
                    </v-row>
                    <v-row>
                        <div style="white-space: pre-line;">
                            {{list.content}}
                        </div>
                    </v-row>
                </div>
            </v-container> -->
        </sub-section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {
            historyList: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // let { history } = await api.v1.linknet.history.gets();
            // this.historyList = history;
            let { history } = await api.v1.linknet.historyen.gets();
            this.historyList = history;
        },
    },
};
</script>

<style lang="scss" scoped>
.history--group {
    .history:not(:last-child) {
        margin-bottom: 40px !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .history--group {
        .history:not(:last-child) {
            margin-bottom: 0 !important;
        }
        .history {
            .history__inner {
                padding-bottom: 60px;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    top: 0;
                    background-color: var(--border-color);
                    z-index: 0;
                }
                .tit {
                    z-index: 2;
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 17px;
                        height: 17px;
                        background-image: url(/images/icon/icon-dot.svg);
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .history:first-child {
            .history__inner {
                padding-top: 60px;
            }
        }
        .history:last-child {
            .history__inner {
                padding-bottom: 0;
                &::after {
                    height: 32px;
                }
            }
        }
        .history:nth-child(2n-1) {
            > [class*="col"] {
                margin-left: 50%;
            }
            .history__inner {
                &::after {
                    left: 0;
                }
                .tit {
                    &::after {
                        left: -20px;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
        .history:nth-child(2n) {
            text-align: right;
            .history__inner {
                &::after {
                    right: -1px;
                }
                .tit {
                    &::after {
                        right: -21px;
                        transform: translate(50%, -50%);
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .history--group {
        .history:nth-child(2n-1) {
            .history__inner {
                .tit {
                    &::after {
                        left: -40px;
                    }
                }
            }
        }
        .history:nth-child(2n) {
            .history__inner {
                .tit {
                    &::after {
                        right: -41px;
                    }
                }
            }
        }
    }
}
@media (min-width: 1200px) {
}

.dot-texts .dot-text:not(:last-child) {
    margin-bottom: 6px;
}
@media (min-width: 1024px) {
    .dot-texts .dot-text:not(:last-child) {
        margin-bottom: 12px;
    }
}
</style>
