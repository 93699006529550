var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-12 mb-lg-20"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h5', {
    staticClass: "tit-txt",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")])]), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-layout', [_c('v-divider', {
    staticClass: "v-divider--vertical--sm grey lighten-3",
    attrs: {
      "vertical": "",
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mt-n4 mt-md-0",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" " + _vm._s(_vm.sc) + " ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }