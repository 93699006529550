var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('icon-frame', {
    staticClass: "mb-4 mb-lg-8",
    attrs: {
      "icon": _vm.icon
    }
  }), _c('p', {
    staticClass: "page-text text-center word-keep-all"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }